<template>
  <div>
    <Dialog
      class="popup"
      :showHeader="null"
      v-model:visible="confirmationPopupVisibility"
      :modal="true"
    >
      <div>
        <label>Are you sure you want to remove this score?</label>
      </div>
      <hr />
      <template #footer>
        <Button label="CLOSE" @click="closeConfirmation" class="p-button-text" />
        <Button label="REMOVE" @click="onDeleteScore" class="p-button-text" />
      </template>
    </Dialog>
    <div class="p-grid background">
      <Toast position="top-center" group="br" class="toast" />
      <div class="p-col-12 p-pb-0">
        <div class="p-grid heading p-pb-0">
          <div class="p-col-2" @click="onBack">
            <i class="pi pi-arrow-left"></i>
          </div>
          <div class="p-col-8 h-title" v-if="!isUpdate">{{ selectedRange.name }}</div>
          <div class="p-col-8 h-title" v-if="isUpdate">{{ selectedParticipant.firstName }}</div>
          <div class="p-col-2"></div>
        </div>
      </div>

      <div class="p-col-12 h-sub-title p-pt-0 p-pb-4">{{ selectedMatch.name }}</div>

      <div class="p-col-12 content-area shadow-top">
        <div class="p-grid p-mt-4">
          <div class="p-col-4 h-text p-text-left p-pb-4 p-pl-3">Participant</div>
          <div class="p-col-4">
            <Button
              type="button"
              class="p-button p-component p-button-rounded p-button-raise"
              @click="onViewTarget"
              label="VIEW TARGET"
            />
          </div>
          <div class="p-col-4">
            <Button
              type="button"
              class="p-button p-component p-button-rounded p-button-raise"
              @click="onAddScore"
              label="ADD SCORE"
            />
          </div>
        </div>

        <div v-if="!isUpdate">
          <div
            class="p-grid item h-70-custom p-m-2"
            v-for="p in rangeScoresResults"
            :key="p.userID"
          >
            <div class="p-col-3 p-as-center">
              <img
                class="p-mr-2 user-image"
                :src="p.image"
                onerror="this.src='https://qspqa.quickscorepro.co.za/img/user-image.jpg'"
              />
            </div>

            <div class="p-col-7 p-as-center p-text-left">
              <div class="vertical-container">
                <div class="box name">{{ p.fullName }}</div>
                <div class="id p-pt-2">Participant number: {{ p.participantNo }}</div>
              </div>
            </div>
            <div class="p-col-2 p-as-center p-text-left" @click="onEditClicked(p)">
              <div class="p-col-2 p-as-center">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M17.6589 3C17.4089 3 17.1489 3.1 16.9589 3.29L15.1289 5.12L18.8789 8.87L20.7089 7.04C21.0989 6.65 21.0989 6.02 20.7089 5.63L18.3689 3.29C18.1689 3.09 17.9189 3 17.6589 3ZM14.06 9.02L14.98 9.94L5.92 19H5V18.08L14.06 9.02ZM3 17.25L14.06 6.19L17.81 9.94L6.75 21H3V17.25Z"
                    fill="black"
                    fill-opacity="0.6"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>

        <div class="p-grid" v-if="isAddNew">
          <div class="p-col-12">
            <ProgressBar
              v-if="getParticipantStatus === 'loading' && !isSearchCleared"
              mode="indeterminate"
              class="progress"
            />
          </div>
          <div class="p-col-10 h-text p-text-left p-pb-4"></div>
          <div class="p-col-2" @click="onClose">
            <i class="pi pi-times-circle"></i>
          </div>
        </div>
        <div class="p-col-12 p-pt-2" v-if="isAddNew">
          <span class="p-float-label">
            <AutoComplete
              v-model="selectedParticipant"
              :suggestions="participantResults"
              @complete="onParticipantNumberChange($event)"
              @item-select="onSelect"
              @clear="onClear()"
              field="participant_No"
              :disabled="isUpdate"
            >
              <template #item="slotProps">
                <div class="p-text-left">
                  {{
                  `${slotProps.item.participant_No} - ${slotProps.item.firstName}`
                  }}
                </div>
              </template>
            </AutoComplete>
            <label for="inputtext-left">Participant number</label>
          </span>
        </div>

        <div class="p-grid" v-if="isAddNew && isSelected">
          <div class="p-field p-col-12 p-pt-3">
            <span class="p-float-label p-input-icon-left">
              <i class="pi pi-user" />
              <InputText
                type="text"
                placeholder="Display name"
                v-model="selectedParticipant.firstName"
                disabled
              />
              <label for="inputtext-left">Display name</label>
            </span>
          </div>

          <div class="p-field p-col-12 p-pt-3">
            <span class="p-float-label p-input-icon-left">
              <i class="pi pi-user-plus" />
              <InputText
                type="number"
                class="p-inputtext p-component p-inputnumber-input"
                v-model.number="scoreModel.score"
              />
              <label for="inputtext-left">Participant score</label>
            </span>
          </div>
        </div>
        <div class="p-col-12" v-if="showButton">
          <Button
            type="button"
            class="p-button-rounded p-button-raised"
            @click="onCaptureScore"
            :label="buttonTItle"
            v-bind:disabled="!isDisabled"
          />
        </div>
        <Button label="REMOVE" class="p-button-text" v-if="isUpdate" @click="openConfirmation" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
export default {
  name: "range-score",
  created() {
    this.$store.commit("captureScoreModule/updateIsAddNew", true);
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      query: "",
      selectedParticipant: { participant_No: "" },
      isSelected: false,
      isUpdate: false,
      scoreModel: {
        score: null
      },
      selectedScore: { matchScoreID: "" },
      isSearchCleared: false
    };
  },
  validations: {
    scoreModel: {
      score: {
        required,
        $lazy: true
      }
    }
  },
  computed: {
    ...mapState("captureScoreModule", [
      "getMatchRangesStatus",
      "selectedMatch",
      "selectedRange",
      "participantResults",
      "getParticipantStatus",
      "scores",
      "isAddNew",
      "rangeScoresResults",
      "getRangeScoresStatus",
      "confirmationPopupVisibility"
    ]),
    ...mapState("authenticate", ["user", "is_admin"]),
    ...mapState("clubModule", ["selectedAdmin"]),
    buttonTItle() {
      return this.isUpdate
        ? "UPDATE"
        : this.isAddNew
        ? "SAVE SCORES"
        : "SAVE SCORES";
    },
    showButton() {
      return this.isSelected || this.rangeScoresResults.length > 0;
    },

    isDisabled() {
      var disabled = this.isSelected;
      return disabled;
    }
  },
  methods: {
    async onParticipantNumberChange(event) {
      this.isSelected = false;
      if (event.query.length >= 1) {
        this.isSearchCleared = false;
        setTimeout(() => {
          this.getParticipant(event.query);
        }, 500);
      }
    },

    onOpenRange(range) {
      this.$store.commit("captureScoreModule/updateSelectedRange", range);
      this.$router.push({ name: "range-score" });
    },

    onViewTarget() {
      this.$router.push({ name: "view-range-targets" });
    },

    onSelect() {
      var captured = [];
      this.rangeScoresResults.forEach(element => {
        captured.push(element.participantNo);
      });
      if (captured.includes(this.selectedParticipant.participant_No)) {
        this.$toast.add({
          severity: "error",
          summary: "Partipant score already captured.",
          group: "br",
          life: 3500
        });
      } else {
        this.isSelected = true;
      }
    },
    onBack() {
      if (this.isUpdate) {
        this.onClose();
      } else {
        this.$store.commit("captureScoreModule/updateSelectedRange", {});
        this.$store.commit("captureScoreModule/updateScores", []);
        this.$router.push({ name: "capture-score" });
      }
    },

    onEditClicked(score) {
      this.selectedParticipant = { participant_No: "" };
      this.isUpdate = true;
      this.selectedParticipant.participant_No = score.participantNo;
      this.selectedParticipant.firstName = score.fullName;
      this.scoreModel.score = score.score;
      this.selectedScore.matchScoreID = score.matchScoreID;
      this.$store.commit("captureScoreModule/updateIsAddNew", true);
      this.isSelected = true;
    },
    onAddScore() {
      this.$store.commit("captureScoreModule/updateIsAddNew", true);
      this.v$.$reset();
    },
    onClose() {
      this.$store.commit("captureScoreModule/updateIsAddNew", false);
      this.isSelected = false;
      this.isUpdate = false;
      this.selectedParticipant = { participant_No: "" };

      this.scoreModel = {
        score: null
      };
    },
    onUpdateScore() {
      this.$store
        .dispatch("captureScoreModule/editParticipantScore", {
          matchScoreID: this.selectedScore.matchScoreID,
          score: this.scoreModel.score
        })
        .then(() => {
          this.$store.dispatch("captureScoreModule/rangeScores", {
            MatchId: this.selectedMatch.matchId,
            RangeID: this.selectedRange.rangeID
          });
        });
      this.onClose();
    },

    onSave() {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.$store.commit("captureScoreModule/addScore", {
          matchID: this.selectedMatch.matchId,
          userID: this.selectedParticipant.id,
          rangeID: this.selectedRange.rangeID,
          score: this.scoreModel.score,
          fullName: `${this.selectedParticipant.firstName} ${this.selectedParticipant.surname}`,
          participantNo: this.selectedParticipant.participant_No,
          division: this.selectedParticipant.division,
          category: this.selectedParticipant.category
        });

        this.selectedParticipant = { participant_No: "" };
        this.$store.commit("captureScoreModule/updateIsAddNew", false);
      }
      this.onClose();
    },
    async submitScores() {
      this.scores.forEach(o => {
        this.$store
          .dispatch("captureScoreModule/captureScore", {
            matchID: o.matchID,
            userID: o.userID,
            rangeID: o.rangeID,
            score: o.score,
            division: o.division,
            category: o.category
          })
          .then(() => {
            this.$store.dispatch("captureScoreModule/rangeScores", {
              MatchId: this.selectedMatch.matchId,
              RangeID: this.selectedRange.rangeID
            });
          });
      });
    },

    async onCaptureScore() {
      if (this.scoreModel.score) {
        if (this.isAddNew && !this.isUpdate) {
          this.onSave();
          await this.submitScores();
        } else if (this.isUpdate) {
          this.onUpdateScore();
        }
      } else {
        this.$toast.add({
          severity: "error",
          summary: "Please enter a score.",
          group: "br",
          life: 2500
        });
      }
    },

    async getParticipant(query) {
      await this.$store
        .dispatch("captureScoreModule/participant", {
          participantNo: query,
          matchId: this.selectedMatch.matchId
        })
        .then(() => {
          if (
            this.participantResults == null ||
            this.participantResults == ""
          ) {
            this.$toast.add({
              severity: "error",
              summary: "No participant found with this number.",
              group: "br",
              life: 2500
            });
          }
        });
      this.query = query;
    },

    onDeleteScore() {
      this.$store
        .dispatch(
          "captureScoreModule/deleteParticipantScore",
          this.selectedScore.matchScoreID
        )
        .then(() => {
          this.$store
            .dispatch("captureScoreModule/rangeScores", {
              MatchId: this.selectedMatch.matchId,
              RangeID: this.selectedRange.rangeID
            })
            .then(() => {
              this.$store.commit(
                "captureScoreModule/updateConfirmationPopupVisibility",
                false
              );
              this.onClose();
            });
        });
    },

    openConfirmation() {
      this.$store.commit(
        "captureScoreModule/updateConfirmationPopupVisibility",
        true
      );
    },

    closeConfirmation() {
      this.$store.commit(
        "captureScoreModule/updateConfirmationPopupVisibility",
        false
      );
    },

    onClear() {
      this.isSearchCleared = true;
    }
  }
};
</script>

<style lang="scss" scoped>
:deep .p-selectbutton .p-button.p-highlight {
  border-bottom: 1px;
  border-bottom-color: #8b0e0f;
  color: #8b0e0f;
  background: transparent;
}

:deep .p-selectbutton .p-button {
  border: none;
  background: transparent;
}

:deep .p-tabview .p-tabview-panels {
  background: transparent;
  padding: 0px;
}

:deep .p-tabview .p-tabview-nav {
  background: transparent;
  border: none;
  justify-content: center;
}

:deep .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: transparent;
  border-color: transparent;
}

:deep .p-tabview .p-tabview-nav li .p-tabview-nav-link {
  background: transparent;
}

.user-image {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}

.title-mini {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #0a0a0a;
}

.sub-value-mini {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #979797;
}

.name {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  color: #000000;
}

.id {
  left: 82px;
  top: 41px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #3e4547;
}

.score {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #279b18;
}

.plus-icon {
  color: #8b0e0f;
}

.popup {
  width: 329px;
}

.p-button.p-component.p-button-rounded.p-button-raise {
  margin-top: -10px;
  font-size: 10px;
}
.pi {
  color: rgba(0, 0, 0, 0.38);
}
:deep .p-inputnumber .p-inputnumber-input {
  margin-left: 30px;
}
</style>