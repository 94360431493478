import ClubService from "../../services/club.service";

const state = () => ({
  status: "",
  searchClubStatus: "",
  selectedClub: {},
  selectedAdmin: false,
  confirmationPopupVisibility:false,
  selectedAdminId:"",
  adminInfo:{},
  clubs: [],
  approvedClubs: [],
  approvedClubList: [],
  approvedClubsStatus: "",
  updateClubStatus: "",
  userClubsStatus: "",
  selectedClubAdmins: [],
  userClubs: [],
  clubMatchPreviews: [],
  viewMatch: [],
  getMatchPreviewStatus: "",
  addClubAdminStatus: "",
  removeClubAdminStatus: "",
  isShowBackButton: false,
});

// getters
const getters = {
  createClubStatus: (state) => state.status,
  getClubList: (state) => state.approvedClubList.map(club => club.image),
  getClubAdminImages: (state) => state.selectedClubAdmins.map(club => club.image),
  getViewMatch: (state) => state.viewMatch,
};

// actions
const actions = {
  async create({ commit }, club) {
    commit("create_club_request");
   await ClubService.addClub(
      club,
      (res) => {
        commit("create_club_success", res.response.club);
      },
      (error) => {
        commit("create_club_error");
        console.error("error", error);
      }
    );
  },
  async update({ commit }, club) {
    commit("update_club_request");
    await ClubService.updateClub(
      club,
      (res) => {
        commit("update_club_success", res.response.club);
      },
      (error) => {
        commit("update_club_error");
        console.error("error", error);
      }
    );
  },

  async approvedClubs({ commit }) {
    commit("get_approved_clubs_request");
    await ClubService.getApprovedClubs(
      (res) => {
        commit("get_approved_clubs_success", res.response);
      },
      (error) => {
        commit("get_approved_clubs_error");
        console.error("error", error);
      }
    );
  },

  async clubList({ commit }, payload) {
    commit("get_club_list_request");
    await ClubService.getClubImages(
      payload,
      (res) => {
        commit("get_club_list_success", res.response);
      },
      (error) => {
        commit("get_club_list_error");
        console.error("error", error);
      }
    );
  },

 async clubMatchPreviews({ commit }) {
    commit("get_match_previews_request");
    await ClubService.clubMatchPreviews(
      (res) => {
        commit("get_match_previews_success", res.response);
      },
      (error) => {
        commit("get_match_previews_error");

        console.error("error", error);
      }
    );
  },

  async clubsByUser({ commit }, payload) {
    commit("clubs_by_user_request");
    await ClubService.clubsByUser(
      payload,
      (res) => {
        commit("clubs_by_user_success", res.response);
      },
      (error) => {
        commit("clubs_by_user_error");
        console.error("error", error);
      }
    );
  },

  async viewMatch({ commit }, payload) {
    return await ClubService.viewMatches(payload)
    .then((res) => commit("update_view_match", res.data.response))
  },

  async clubAdminUsers({ commit }, clubId) {
    await ClubService.getClubAdmins(
      clubId,
      (res) => {
        commit("club_admins_success", res);
      },
      (error) => {
        console.error("error", error);
      }
    );
  },

  async addClubAdmin({ commit }, admin) {
    commit("create_add_admin_request");
    await ClubService.addClubAdmin(
      admin,
      (res) => {
        commit("create_add_admin_success", res.response);
      },
      (error) => {
        commit("create_add_admin_error");
        console.error("error", error);
      }
    );
  },

  async removeClubAdmin({ commit }, admin) {
    commit("remove_admin_request");
    await ClubService.removeClubAdmin(
      admin,
      (res) => {
        commit("remove_admin_success", res.response);
      },
      (error) => {
        commit("remove_admin_error");
        console.error("error", error);
      }
    );
  },
};

// mutations
const mutations = {
  resetStatus(state) {
    state.status = "";
    state.updateClubStatus = "";
    state.isShowBackButton = false;
  },
  update_view_match(state, payload) {
    state.viewMatch = payload;
  },
  //
  create_club_request(state) {
    state.status = "loading";
  },
  create_club_success(state, club) {
    state.status = "success";
    state.club = club; //TODO
  },
  create_club_error(state) {
    state.status = "error";
  },
  //
  update_club_request(state) {
    state.updateClubStatus = "loading";
  },
  update_club_success(state, club) {
    state.updateClubStatus = "success";
    console.log("Updating status", state.updateClubStatus)
    state.club = club; //TODO p
  },
  update_club_error(state) {
    state.updateClubStatus = "error";
  },
  //
  get_approved_clubs_request(state) {
    state.approvedClubsStatus = "loading";
  },
  get_approved_clubs_success(state, approvedClubs) {
    state.approvedClubsStatus = "success";
    state.approvedClubs = approvedClubs;
  },
  get_approved_clubs_error(state) {
    state.approvedClubsStatus = "error";
  },
  //--clubs by user
  get_club_list_request(state) {
    state.searchClubStatus = "loading";
  },

  get_club_list_success(state, approvedClubList) {
    state.searchClubStatus = "success";
    state.approvedClubList = approvedClubList;
  },

  get_club_list_error(state) {
    state.searchClubStatus = "error";
  },


  clubs_by_user_request(state) {
    state.searchClubStatus = "loading";
    state.userClubsStatus = "loading";
  },
  clubs_by_user_success(state, userClubs) {
    state.userClubsStatus = "success";
    state.searchClubStatus = "success";
    state.userClubs = userClubs;
  },
  clubs_by_user_error(state) {
    state.searchClubStatus = "error";
    state.userClubsStatus = "error";
  },
  //
  updateSelectedClub(state, selectedClub) {
    state.selectedClub = selectedClub;
  },

  //Popup admin modal
  updateSelectedAdminId(state, selectedAdminId) {
    state.selectedAdminId = selectedAdminId;
  },

  updateAdminInfo(state, adminInfo) {
    state.adminInfo = adminInfo;
  },

  updateSelectedAdmin(state, selectedAdmin) {
    state.selectedAdmin = selectedAdmin;
  },
  //Popup admin modal end

  //Confirmation Popup admin modal

  updateConfirmationPopupVisibility(state, confirmationPopupVisibility) {
    state.confirmationPopupVisibility = confirmationPopupVisibility;
  },

  //Confirmation Popup admin modalend

  get_match_previews_request(state) {
    state.getMatchPreviewStatus = "loading";
  },
  get_match_previews_success(state, previews) {
    state.clubMatchPreviews = previews.sort((a, b) =>
      a.matchCount < b.matchCount ? 1 : b.matchCount < a.matchCount ? -1 : 0
    );
    state.getMatchPreviewStatus = "success";
  },
  get_match_previews_error(state) {
    state.getMatchPreviewStatus = "error";
  },

  club_admins_success(state, admins) {
    state.selectedClubAdmins = admins;
  },

  //Add Club Admin
  create_add_admin_request(state) {
    state.addClubAdminStatus = "loading";
  },
  create_add_admin_success(state, res) {
    state.addClubAdminStatus = "success";
    state.res = res;
  },
  create_add_admin_error(state) {
    state.addClubAdminStatus = "error";
  },

  //Remove Club Admin
  remove_admin_request(state) {
    state.removeClubAdminStatus = "loading";
  },
  remove_admin_success(state, res) {
    state.removeClubAdminStatus = "success";
    state.res = res;
  },
  remove_admin_error(state) {
    state.removeClubAdminStatus = "error";
  },

  show_back_button(state) {
    state.isShowBackButton = true;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
