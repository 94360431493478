<template>
   <div class="p-grid background">
      <div class="p-col-12 p-pb-0">
         <div class="p-grid heading p-pb-0">
            <div class="p-col-2" @click="onBack">
               <i class="pi pi-arrow-left"></i>
            </div>
            <div class="p-col-8 h-title">
              <HeaderLogo/>
              <h4>Edit score</h4>
            </div>
            <div class="p-col-2"></div>
         </div>
      </div>
      <div class="p-col-12 h-sub-title p-pt-0 p-pb-4">
         {{ selectedMatch.name }}
      </div>
      <div class="p-col-12 content-area shadow-top">
         <Toast position="top-center" group="br" class="toast"/>
         <div class="p-col-12">
            <ProgressBar
               v-if="getParticipantStatus === 'loading'"
               mode="indeterminate"
               class="progress"
               />
         </div>
         <div class="p-grid">
            <div class="p-col-10 h-text p-text-left p-pb-4 p-pl-3">Participant</div>
         </div>
         <div v-if="!isUpdate">
            <div
               class="p-grid item h-70-custom p-m-2"
               v-for="p in rangeScoresResults"
               :key="p.userID"
               >
               <div class="p-col-3 p-as-center">
                  <Avatar :image="p.image" size="large" shape="circle" />
               </div>
               <div class="p-col-7 p-as-center p-text-left">
                  <div class="vertical-container">
                     <div class="box name">{{ p.fullName }}</div>
                     <div class="id p-pt-2">Participant number: {{ p.participantNo }}</div>
                  </div>
               </div>
               <div class="p-col-2 p-as-center p-text-left" @click="onEditClicked(p)">
                  <div class="p-col-2 p-as-center">
                     <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.6589 3C17.4089 3 17.1489 3.1 16.9589 3.29L15.1289 5.12L18.8789 8.87L20.7089 7.04C21.0989 6.65 21.0989 6.02 20.7089 5.63L18.3689 3.29C18.1689 3.09 17.9189 3 17.6589 3ZM14.06 9.02L14.98 9.94L5.92 19H5V18.08L14.06 9.02ZM3 17.25L14.06 6.19L17.81 9.94L6.75 21H3V17.25Z" fill="black" fill-opacity="0.6"/>
                     </svg>
                  </div>
               </div>
            </div>
         </div>
         <div class="p-grid" v-if="isSelected">
            <div class="p-field p-col-12 p-pt-3">
               <span class="p-input-icon-left">
                  <i class="pi pi-user" />
                  <InputText
                     type="text"
                     placeholder="Display name"
                     v-model="selectedParticipant.participant_No"
                     disabled
                     />
               </span>
            </div>
            <div class="p-field p-col-12 p-pt-3">
               <span class="p-input-icon-left">
                  <i class="pi pi-user" />
                  <InputText
                     type="text"
                     placeholder="Display name"
                     v-model="selectedParticipant.firstName"
                     disabled
                     />
               </span>
            </div>
            <div class="p-field p-col-12 p-pt-3">
               <span class="p-input-icon-left">
                  <input type="number" class="p-inputtext p-component p-inputnumber-input" v-model.number="scoreModel.score">
               </span>
            </div>
         </div>
         <div class="p-col-12" v-if="showButton && isDisabled">
            <Button
               type="button"
               class="p-button-rounded p-button-raised"
               @click="onCaptureScore"
               :label="buttonTItle"
               />
         </div>
         <Button label="VIEW SCORES" class="p-button-text" v-if="!isSelected" @click="onViewScores()"/>
         <Button label="CANCEL" class="p-button-text" v-if="isSelected" @click="onClose()"/>
      </div>
   </div>
</template>

<script>
import { mapState } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import HeaderLogo from "../../../shared/HeaderLogo.vue"
export default {
  name: "range-score",
  components: { HeaderLogo },
  created() {
    this.$store.commit("captureScoreModule/updateIsAddNew", true);
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      query: "",
      selectedParticipant: { participant_No: ""},
      isSelected: false,
      isUpdate: false,
      scoreModel: {
        score: null,
      },
      selectedScore: {matchScoreID: ""},
    };
  },
  validations: {
    scoreModel: {
      score: {
        required,
        $lazy: true,
      },
    },
  },
  computed: {
    ...mapState("captureScoreModule", [
      "getMatchRangesStatus",
      "selectedMatch",
      "selectedRange",
      "participantResults",
      "getParticipantStatus",
      "scores",
      "isAddNew",
      "rangeScoresResults",
      "getRangeScoresStatus",
      "selectedUserId"
    ]),
    ...mapState("authenticate", ["user", "is_admin"]),
    buttonTItle() {
      return this.isUpdate ? "UPDATE" : this.isAddNew ? "CAPTURE SCORES" : "CAPTURE SCORES";
    },
    showButton() {
      return this.isSelected || this.rangeScoresResults.length > 0;
    },

    isDisabled() {
      var disabled = this.isSelected;
      return disabled;
    }
    
  },
  methods: {
    async onParticipantNumberChange(event) {
      this.isSelected = false;
      if (event.query.length >= 1 && event.query !== this.query) {
        setTimeout(() => {
          this.getParticipant(event.query);
        }, 500);
      }
    },

    onSelect() {
      this.isSelected = true;
    },
    onBack() {
        this.$store.commit("captureScoreModule/updateSelectedRange", {});
        this.$store.commit("captureScoreModule/updateScores", []);
        this.$router.push({ name: "admin-score-edit" });
    },

    onEditClicked(score) {
      this.isUpdate = true;
      this.selectedParticipant.participant_No = score.participantNo;
      this.selectedParticipant.firstName = score.fullName;
      this.scoreModel.score = score.score;
      this.selectedScore.matchScoreID = score.matchScoreID;
      this.$store.commit("captureScoreModule/updateIsAddNew", true);
      this.isSelected = true;
    },
    onClose() {
      this.$store.commit("captureScoreModule/updateIsAddNew", false);
      this.isSelected = false;
      this.isUpdate = false;
      this.selectedParticipant = { participant_No: "" };

      this.scoreModel = {
        score: null,
      };
    },
    onViewScores(){
                this.$store.dispatch(
                    "captureScoreModule/getMyScores", {
                        MatchId: this.selectedMatch.matchID,
                        UserId: this.selectedUserId
                    }).then(() => {
                    this.$router.push({ name: "admin-score-board" });
                });
    },
    onUpdateScore() {
      this.$store.dispatch("captureScoreModule/editParticipantScore", {
        matchScoreID: this.selectedScore.matchScoreID,
        score: this.scoreModel.score,
        }).then(() => {
          this.$store.dispatch("captureScoreModule/rangeScoresPerUser", {
          MatchId: this.selectedMatch.matchID,
          RangeID: this.selectedRange.rangeID,
          UserId: this.selectedUserId
        });
      });
      this.onClose();
    },

    async onCaptureScore() {
      if(this.scoreModel.score){
        this.onUpdateScore();
      }else{
        this.$toast.add({ severity: 'error', summary: "Please enter a score.", group: 'br', life: 2500 });
      }
    },
    
    async getParticipant(query) {
      await this.$store.dispatch("captureScoreModule/participant", {
        participantNo: query,
        matchId: this.selectedMatch.matchID,
        });
      this.query = query;
    },
  },
};
</script>

<style lang="scss" scoped>
:deep .p-selectbutton .p-button.p-highlight {
  border-bottom: 1px;
  border-bottom-color: #8b0e0f;
  color: #8b0e0f;
  background: transparent;
}

:deep .p-selectbutton .p-button {
  border: none;
  background: transparent;
}

:deep .p-tabview .p-tabview-panels {
  background: transparent;
  padding: 0px;
}

:deep .p-tabview .p-tabview-nav {
  background: transparent;
  border: none;
  justify-content: center;
}

:deep .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: transparent;
  border-color: transparent;
}

:deep .p-tabview .p-tabview-nav li .p-tabview-nav-link {
  background: transparent;
}

.title-mini {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #0a0a0a;
}

.sub-value-mini {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #979797;
}

.name {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  color: #000000;
}

.id {
  left: 82px;
  top: 41px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #3E4547;
}

.score {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #279b18;
}

.plus-icon {
  color: #8B0E0F;
}

.popup{
  width:329px;
}
</style>