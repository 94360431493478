import BranchService from "../../services/branch.service";

const state = () => ({
  selectedClubBranches: [],
  createBranchStatus: "",
  updateBranchStatus: "",
  selectedBranch: {}, //editing
});

// getters
const getters = {
  getClubBranchesImages: (state) => state.selectedClubBranches.map(branch => branch.image),
};

// actions
const actions = {
  async create({ commit }, branch) {
    commit("create_branch_request");
    await BranchService.add(
      branch,
      (res) => {
        commit("create_branch_success", res.response.branch);
      },
      (error) => {
        commit("create_branch_error");
        console.error("error", error);
      }
    );
  },

  async update({ commit }, branch) {
    commit("update_branch_request");
    await BranchService.update(
      branch,
      (res) => {
        commit("update_branch_success", res.response.branch);
      },
      (error) => {
        commit("update_branch_error");
        console.error("error", error);
      }
    );
  },

  branchesForClub({ commit }, clubId) {
    BranchService.getBranchesByClub(
      clubId,
      (res) => {
        commit("get_branches_success", res.response);
      },
      (error) => {
        console.error("error", error);
      }
    );
  },
};

// mutations
const mutations = {
  resetStatus(state) {
    state.createBranchStatus = "";
  },
  //--create
  create_branch_request(state) {
    state.createBranchStatus = "loading";
  },
  create_branch_success(state, res) {
    state.createBranchStatus = "success";
    state.res = res;
  },
  create_branch_error(state) {
    state.createBranchStatus = "error";
  },
  //--update
  update_branch_request(state) {
    state.updateBranchStatus = "loading";
  },
  update_branch_success(state, res) {
    state.updateBranchStatus = "success";
    state.res = res;
  },
  update_branch_error(state) {
    state.updateBranchStatus = "error";
  },
  //
  get_branches_success(state, branches) {
    state.selectedClubBranches = branches;
  },
  updateSelectedBranch(state, branch) {
    state.selectedBranch = branch;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
