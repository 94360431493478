<template>
  <faq-desktop-vue v-if="!isMobile()"/>
  <div class="p-grid background" v-if="isMobile()">
    <div class="p-col-12">
      <div class="p-grid heading">
        <div class="p-col-1 p-pb-5" @click="onBack()">
          <i class="pi pi-arrow-left"></i>
        </div>
        <div class="p-col-11">
          <div class="p-col-11 p-pt-3">
            <HeaderLogo />
          </div>
        </div>
        <div class="p-col-12">FAQ</div>
      </div>
    </div>

    <div class="p-col-12 p-pt-4 p-px-4 content-area list-items">
      <Accordion>
        <AccordionTab :header="faq.heading" v-for="faq in faqs" :key="faq.faqID">
          <ScrollPanel class="scroll">
            <p>{{ faq.description }}</p>
          </ScrollPanel>
        </AccordionTab>
      </Accordion>

      <div class="p-col-12 p-pt-6">
        <Button
          type="button"
          class="p-button-rounded p-button-raised"
          @click="onBack"
          label="GO BACK"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import HeaderLogo from "../../shared/HeaderLogo.vue";
import FaqDesktopVue from './FaqDesktop.vue';
export default {
  name: "faq",
  components: { HeaderLogo, FaqDesktopVue },
  created() {
    this.$store.dispatch("utilModule/faqs");
  },

  computed: {
    ...mapState("utilModule", ["faqs", "getFaqsStatus"])
  },
  methods: {
    onBack() {
      this.$router.go(-1);
    },    
    isMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.scroll {
  width: 100%;
  height: 150px !important;
}
</style>