<template>
  <div class="p-grid background">
    <div class="p-col-12">
      <div class="p-grid heading">
        <div class="p-col-2">
          <router-link to="/club">
            <i class="pi pi-arrow-left"></i>
          </router-link>
        </div>
        <div class="p-col-8">
          <HeaderLogo />
          <h4>Target bank</h4>
        </div>
        <div class="p-col-2">
          <router-link to="/target/create" v-if="is_admin">
            <i class="pi pi-plus"></i>
          </router-link>
        </div>
      </div>
    </div>

    <div class="p-col-12 p-pb-4 search">
      <span class="p-input-icon-left">
        <i class="pi pi-search" />
        <InputText type="text" v-model="search" placeholder="Search by target name " />
      </span>
    </div>

    <div class="p-col-12 p-px-2 p-py-4 content-area">
      <div class="p-col-12" v-if="targetsFiltered.length == 0">No targets found</div>
      <div class="p-col-12" v-for="t in targetsFiltered" :key="t.targetID">
        <target-item :target="t" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import TargetItem from "./TargetItem.vue";
import HeaderLogo from "../../shared/HeaderLogo.vue"
export default {
  components: { TargetItem, HeaderLogo },
  name: "Targets",
  created() {
    this.$store.dispatch(
      "targetModule/targetsForClub",
      this.selectedClub.clubID
    );
  },
  data() {
    return { search: "" };
  },
  computed: {
    ...mapState("clubModule", ["selectedClub"]),
    ...mapState("targetModule", ["selectedClubTargets"]),

    targetsFiltered() {
      return this.selectedClubTargets.filter(
        (a) =>
          this.search === "" ||
          a.name.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
:deep .search {
  .p-input-filled .p-inputtext {
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
  }
  .p-inputtext {
    border-radius: 30px;
  }
}
</style>