<template>
  <div>
    <confirmation
      v-if="createClubStatus === 'success'"
      :title="'New club has successfully been created '"
      :status="createClubStatus"
      :fallback="'/clubs'"
      :mutation="'clubModule/resetStatus'"
    />

    <div class="p-grid background" v-if="createClubStatus !== 'success'">
      <div class="p-col-12">
        <div class="p-grid heading">
          <div class="p-col-2">
            <router-link to="/dashboard">
              <i class="pi pi-arrow-left"></i>
            </router-link>
          </div>
          <div class="p-col-8">Create club</div>
          <div class="p-col-2" />
        </div>
      </div>

      <div class="p-col-12 h-50-custom">
        <div class="open-file">
          <input
            ref="image"
            type="file"
            accept="image/*"
            @change="uploadImage($event)"
            class="pi pi-arrow-left"
            id="upload-photo"
          />
        </div>
        <div class="picture-holder">
          <label for="upload-photo">
            <img
              class="p-mr-2 bg-grey-custom clubLogo"
              size="xlarge"
              v-bind:src="
                existingClubSelected ? clubModel.image : clubModel.userPhoto
              "
            />
          </label>
        </div>
      </div>
      <div class="p-col-12 p-pt-4 content-area">
        <div class="p-fluid p-formgrid p-grid">
          <div
            class="p-col-12 error-message required-image-error"
            v-if="v$.clubModel.picture.required.$invalid"
          >
            {{ constants.IMAGE_VALIDATION.IMAGE_REQUIRED }}
          </div>
          <div
            class="p-col-12 error-message required-image-error"
            v-if="isSizeExceeded"
          >
            {{ constants.IMAGE_VALIDATION.EXCEEDED_IMAGE_SIZE_ERROR }}
          </div>
          <div class="p-col-12 p-text-left p-pt-4 p-pb-3">Club details</div>
          <div class="p-field p-col-12">
            <span class="p-float-label p-input-icon-left">
              <i class="pi pi-globe" />
              <AutoComplete
                v-model="clubSearch"
                :suggestions="filteredList"
                @item-select="onClubSelected($event)"
                @complete="onClubNameChange($event)"
                field="clubName"
                :class="{ 'p-invalid': v$.clubSearch.required.$invalid }"
              />
              <label for="inputtext-left ">Club name</label>
            </span>
            <p class="error-message" v-if="v$.clubSearch.required.$invalid">
              * Club name is required
            </p>
          </div>

          <div class="p-field p-col-12">
            <span class="p-float-label p-input-icon-left">
              <i class="pi pi-envelope" />
              <InputText
                id="email"
                type="email"
                v-model="clubModel.email"
                :disabled="existingClubSelected"
                :class="{ 'p-invalid': v$.clubModel.email.required.$invalid }"
              />
              <label for="inputtext-left ">Email address</label>
            </span>
            <p
              class="error-message"
              v-if="v$.clubModel.email.required.$invalid"
            >
              {{ constants.FORM_VALIDATION_ERRORS.EMAIL_REQUIRED }}
            </p>
            <p class="error-message" v-if="v$.clubModel.email.email.$invalid">
              {{ constants.FORM_VALIDATION_ERRORS.EMAIL_INVALID }}
            </p>
          </div>

          <div class="p-field p-col-12">
            <span class="p-float-label p-input-icon-left">
              <i class="pi pi-directions" />
              <InputText
                id="clubAddress"
                type="text"
                v-model="clubModel.clubAddress"
                :disabled="existingClubSelected"
                :class="{
                  'p-invalid': v$.clubModel.clubAddress.required.$invalid
                }"
              />
              <label for="inputtext-left ">Location</label>
            </span>
            <p
              class="error-message"
              v-if="v$.clubModel.clubAddress.required.$invalid"
            >
              {{ constants.FORM_VALIDATION_ERRORS.LOCATION_REQUIRED }}
            </p>
          </div>
          <div class="p-field p-col-12 p-text-left">
            <span class="p-float-label p-input-icon-left">
              <i class="pi pi-search index-1" />
              <Dropdown
                v-model="clubModel.provinceID"
                :options="provinces"
                class="p-pl-4"
                optionLabel="name"
                optionValue="value"
                :disabled="existingClubSelected"
                :class="{
                  'p-invalid': v$.clubModel.provinceID.required.$invalid
                }"
              />
              <label for="inputtext-left" class="inputtext-left province "
                >Province</label
              >
            </span>
          </div>

          <div class="p-field p-col-12">
            <span class="p-float-label p-input-icon-left">
              <i class="pi pi-map-marker" />
              <InputText
                id="googleMap"
                type="text"
                v-model="clubModel.googleMaps"
                :disabled="existingClubSelected"
                :class="{
                  'p-invalid': v$.clubModel.googleMaps.required.$invalid
                }"
              />
              <label for="inputtext-left ">Google maps link</label>
            </span>
            <p
              class="error-message"
              v-if="v$.clubModel.googleMaps.required.$invalid"
            >
              {{ constants.FORM_VALIDATION_ERRORS.GOOGLE_LINK_REQUIRED }}
            </p>
          </div>

          <div class="p-col-12 p-text-left p-pt-4 p-pb-3">Club admin</div>

          <div class="p-col-12 p-field">
            <span class="p-float-label p-input-icon-left">
              <i class="pi pi-user" />

              <InputText type="text" v-model="user.firstName" disabled />
              <label for="inputtext-left ">Admin name</label>
            </span>
          </div>
          <div class="p-field p-col-12">
            <span class="p-float-label p-input-icon-left">
              <i class="pi pi-envelope" />

              <InputText
                type="text"
                placeholder="email address"
                v-model="user.email"
                disabled
              />
              <label for="inputtext-left ">email address</label>
            </span>
          </div>
          <div class="p-col-12">
            <div
              class="p-col-12 error-message required-image-error"
              v-if="v$.clubModel.picture.required.$invalid"
            >
              {{ constants.IMAGE_VALIDATION.IMAGE_REQUIRED }}
            </div>
            <div
              class="p-col-12 error-message required-image-error"
              v-if="isSizeExceeded"
            >
              {{ constants.IMAGE_VALIDATION.EXCEEDED_IMAGE_SIZE_ERROR }}
            </div>
            <p class="error-message" v-if="v$.clubSearch.required.$invalid">
              * Club name is required
            </p>
            <p
              class="error-message"
              v-if="v$.clubModel.email.required.$invalid"
            >
              {{ constants.FORM_VALIDATION_ERRORS.EMAIL_REQUIRED }}
            </p>
            <p class="error-message" v-if="v$.clubModel.email.email.$invalid">
              {{ constants.FORM_VALIDATION_ERRORS.EMAIL_INVALID }}
            </p>
            <p
              class="error-message"
              v-if="v$.clubModel.clubAddress.required.$invalid"
            >
              {{ constants.FORM_VALIDATION_ERRORS.LOCATION_REQUIRED }}
            </p>
          </div>
          <div class="p-col-12">
            <Message
              severity="error"
              v-if="createClubStatus === 'error'"
              :life="3000"
              :sticky="false"
              >Club creation failed. Please try again</Message
            >
          </div>
          <div class="p-field p-col-12 p-pt-4">
            <Button
              class="p-button-rounded p-button-raised"
              type="button"
              @click="createClub()"
              :label="buttonTitle"
            >
              <ProgressSpinner
                v-if="createClubStatus === 'loading'"
                class="progress-spinner-custom"
                strokeWidth="5"
                animationDuration=".5s"
              />
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, email, minLength, maxLength } from "@vuelidate/validators";
import { mapState, mapGetters } from "vuex";
import CameraIcon from "../../assets/icons/camera-icon.svg";
import Confirmation from "../manage-clubs/Confirmation";

export default {
  components: {
    Confirmation
  },
  name: "createClub",
  setup() {
    return { v$: useVuelidate() };
  },
  created() {
    this.$store.dispatch("utilModule/provinces");
    this.$store.dispatch("clubModule/approvedClubs");
  },
  data() {
    return {
      existingClubSelected: false,
      selectedAdmin: { email: "", userName: "", id: "" },
      filteredList: null,
      clubSearch: "",
      pic: "",
      clubModel: {
        clubName: "",
        clubAddress: "",
        provinceID: 0,
        Website: "",
        email: "",
        Telephone: "",
        googleMaps: "",
        userPhoto: CameraIcon,
        userImageUrl: CameraIcon,
        Description: "",
        UserID: "",
        picture: ""
      },
      isSizeExceeded: false
    };
  },
  validations: {
    clubSearch: {
      required,
      minLength: minLength(3),
      $lazy: true
    },
    clubModel: {
      clubAddress: {
        required,
        maxLength: maxLength(120),
        $lazy: true
      },
      provinceID: {
        required,
        $lazy: true
      },

      email: {
        required,
        email,
        $lazy: true
      },

      googleMaps: {
        required,
        $lazy: true
      },

      picture: {
        required,
        $lazy: true
      }
    }
  },
  computed: {
    ...mapGetters({
      createClubStatus: "clubModule/createClubStatus"
    }),
    ...mapState("clubModule", ["status", "approvedClubs"]),
    ...mapState("utilModule", ["status", "provinces"]),
    ...mapState("authenticate", ["user"]),
    buttonTitle() {
      return this.existingClubSelected ? "Go to club" : "Create Club";
    }
  },

  methods: {
    onClubSelected(event) {
      this.clubModel = event.value;
      this.existingClubSelected = true;
      this.filteredList = null;
    },
    onClubNameChange(event) {
      this.existingClubSelected = false;
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.filteredList = [...this.approvedClubs];
        } else {
          this.filteredList = this.approvedClubs.filter(club => {
            return club.clubName
              .toLowerCase()
              .startsWith(event.query.toLowerCase());
          });
        }
      }, 250);
    },

    async getUsers(query) {
      await this.$store.dispatch("authenticate/userSearch", query);
      this.query = query;
    },
    async createClub() {
      if (this.existingClubSelected) {
        this.$store.commit("clubModule/updateSelectedClub", this.clubModel);
        this.$router.push({ name: "club" });
      } else {
        this.v$.$touch();
        if (!this.v$.$invalid) {
          this.isSubmitted = true;
          this.clubModel.UserID = this.user.id;
          this.clubModel.clubName = this.clubSearch;

          let form = new FormData();
          form.append("clubName", this.clubModel.clubName);
          form.append("userID", this.clubModel.UserID);
          form.append("email", this.clubModel.email);
          form.append("clubAddress", this.clubModel.clubAddress);
          form.append("provinceID", this.clubModel.provinceID);
          form.append("googleMaps", this.clubModel.googleMaps);
          form.append("UserPhoto", this.clubModel.picture);

          await this.$store.dispatch("clubModule/create", form).then(() => {
            this.$store.commit("authenticate/updateAdmin");
          });
        }
      }
      if (
        !this.clubModel.clubName ||
        !this.clubModel.email ||
        !this.clubModel.clubAddress ||
        !this.clubModel.picture
      ) {
        await this.scrollToScreenTop();
      }
    },

    uploadImage(event) {
      this.isSizeExceeded = false;
      this.clubModel.picture = event.target.files[0];
      const input = this.$refs.image;
      const files = input.files;
      this.fileSize = files[0].size;
      if (this.fileSize >= this.constants.IMAGE_VALIDATION.IMAGE_MAX_SIZE) {
        this.isSizeExceeded = true;
        this.clubModel.picture = "";
      }
      if (
        files &&
        files[0] &&
        this.fileSize < this.constants.IMAGE_VALIDATION.IMAGE_MAX_SIZE
      ) {
        const reader = new FileReader();
        reader.onload = e => {
          this.clubModel.userPhoto = e.target.result;
        };
        reader.readAsDataURL(files[0]);
        this.$emit("input", files[0]);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.p-avatar img {
  border-radius: 50px;
}
.pi.pi-globe {
  z-index: 1;
}
:deep .p-autocomplete-input {
  margin-left: 30px;
}
.required-image-error {
  margin-top: 20px;
}
.clubLogo {
  width: 85px;
  height: 80px;
  border-radius: 50%;
}
.image-c {
  opacity: 0;
}
</style>