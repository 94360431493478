<template>
   <div class="p-grid background">
      <div class="p-col-12">
         <div class="p-grid heading">
            <div class="p-col-2">
               <router-link to="/dashboard">
                  <i class="pi pi-arrow-left"></i>
               </router-link>
            </div>
            <div class="p-col-8">
                <HeaderLogo />
               <h4>Find match</h4>
            </div>
            <div class="p-col-2">
               <router-link to="/clubs/create" v-if="is_admin">
                  <i class="pi pi-plus"></i>
               </router-link>
            </div>
         </div>
      </div>
      <div class="p-col-12 p-pb-4 search">
         <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText type="text" v-model.trim="searchClub" @keyup="searchClubByName" placeholder="Search by club name " />
         </span>
      </div>
      <div class="p-col-12 p-p-4 content-area">
         <div class="p-col-12">
            <ProgressBar
               v-if="getMatchPreviewStatus === 'loading'"
               mode="indeterminate"
               class="progress"
               />
         </div>
         <div class="p-col-12" v-if="MatchList.length === 0">{{constants.EMPTY_STATE_TEXT.EMPTY_CLUB_LIST}}</div>
         <div
            class="p-col-12 pointer"
            v-for="matchData in MatchList"
            :key="matchData.clubID"
            @click="onViewClub(matchData)"
            >
            <div class="p-grid item h-80-custom">
               <div class="p-col-4 p-as-center">
                  <Avatar :image="matchData.image" class="p-mr-2" size="xlarge" />
               </div>
               <div class="p-col-8 p-as-center p-text-left">
                  <div class="vertical-container">
                     <div class="title-mini">{{ matchData.name }}</div>
                     <div class="sub-value-mini">
                        {{
                        matchData.matchCount > 0
                        ? `${matchData.matchCount} matches available`
                        : "No matches at this time "
                        }}
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div v-if="!is_admin && viewMatch.length > 9" @click="loadMore">
            <LoadMore/>
         </div>
      </div>
   </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import LoadMore from "../../main/LoadMore"
import HeaderLogo from "../../shared/HeaderLogo.vue"
export default {
    name: "matchPreviews",
    components: {
        LoadMore,
        HeaderLogo
    },
    created() {
        this.skip = 0;
        this.clubList = [];
        this.$store.dispatch("clubModule/viewMatch", {
            skip: this.skip,
            clubName: this.searchClub
        }).then(() => {
            this.viewMatch.forEach((x) => {
                this.MatchList.push({
                    clubID: x.clubID,
                    name: x.name,
                    matchCount: x.matchCount,
                    image: x.image,
                });
            })
        });
    },
    data() {
        return {
            skip: 0,
            MatchList: [],
            searchClub: null
        };
    },
    computed: {
        ...mapGetters({
            viewMatch: "clubModule/getViewMatch",
        }),
        ...mapState("clubModule", [
            "status",
            "clubMatchPreviews",
            "getMatchPreviewStatus",
        ]),
        ...mapState("authenticate", ["user", "is_admin"]),
    },
    methods: {
        onViewClub(club) {
            this.$router.push({
                name: "matches"
            });
            this.$store.commit("clubModule/updateSelectedClub", club);
        },
        searchClubByName() {
            if (this.searchClub) {
                this.skip = 0;
                this.$store.dispatch("clubModule/viewMatch", {
                    skip: this.skip,
                    clubName: this.searchClub
                }).then(() => {
                    this.MatchList = [];
                    this.viewMatch.forEach((x) => {
                        this.MatchList.push({
                            clubID: x.clubID,
                            name: x.name,
                            matchCount: x.matchCount,
                            image: x.image,
                        });
                    })
                });
            } else {
                this.skip = 0;
                this.$store.dispatch("clubModule/viewMatch", {
                    skip: this.skip,
                    clubName: null
                }).then(() => {
                    this.MatchList = [];
                    this.viewMatch.forEach((x) => {
                        this.MatchList.push({
                            clubID: x.clubID,
                            name: x.name,
                            matchCount: x.matchCount,
                            image: x.image,
                        });
                    })
                });
            }
        },
        loadMore() {
            if (this.searchClub) {
                this.skip = this.skip + 10;
                this.$store.dispatch("clubModule/viewMatch", {
                    skip: this.skip,
                    clubName: this.searchClub
                }).then(() => {
                    this.viewMatch.forEach((x) => {
                        this.MatchList.push({
                            clubID: x.clubID,
                            name: x.name,
                            matchCount: x.matchCount,
                            image: x.image,
                        });
                    })
                });
            } else {
                this.skip = this.skip + 10;
                this.$store.dispatch("clubModule/viewMatch", {
                    skip: this.skip,
                    clubName: null
                }).then(() => {
                    this.viewMatch.forEach((x) => {
                        this.MatchList.push({
                            clubID: x.clubID,
                            name: x.name,
                            matchCount: x.matchCount,
                            image: x.image,
                        });
                    })
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
:deep .search {
  .p-input-filled .p-inputtext {
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
  }
  .p-inputtext {
    border-radius: 30px;
  }
}

.title-mini {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #0a0a0a;
}

.sub-value-mini {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #979797;
}

:deep {
  .p-avatar img {
    width: auto;
    height: 100%;
    padding-left: 18px;
}
  .p-avatar.p-avatar-xl {
    width: 100%;
    height: 56px
  }
}
</style>