<template>
  <div>
    <confirmation
      v-if="isSubmitted"
      :title="'New match has successfully been created '"
      :status="status"
      :fallback="'/club'"
    />

    <div
      class="p-grid p-ai-start vertical-container container"
      v-if="!isSubmitted"
    >
      <div class="p-col-12 p-text-left" v-if="page !== 5">
        <div @click="onBack"><i class="pi pi-arrow-left"></i></div>
      </div>
      <div class="p-col-6 p-pl-4" v-if="page !== 5">
        <radial-progress-bar
          :diameter="100"
          :completed-steps="page"
          :total-steps="4"
          startColor="#093248"
          stopColor="#093248"
          innerStrokeColor="lightgrey"
        >
          <div class="next">{{ page }}of 4</div>
        </radial-progress-bar>
      </div>
      <div class="p-col-16 p-pt-5" v-if="page !== 5">
        <div class="box">
          <div class="title-event">{{ headers[page].title }}</div>
          <div class="next">{{ headers[page].next }}</div>
        </div>
      </div>

      <div class="p-col-12">
        <add-match
          v-on:new-match="matchAdded"
          v-show="page === constants.PAGE_NUMBERS.MATCH_DETAILS"
        />
        <add-range
          v-on:new-range-list="rangeAdded"
          v-show="page === constants.PAGE_NUMBERS.RANGE_DETAILS"
        />
        <add-official
          v-on:new-officials="officialAdded"
          v-show="page === constants.PAGE_NUMBERS.OFFICIAL_DETAILS"
        />
        <advertisment
          v-on:new-ad-list="advertAdded"
          v-show="page === constants.PAGE_NUMBERS.SPONSOR_DETAILS"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AddRange from "./range/AddRange.vue";
import AddMatch from "./match/AddMatch.vue";
import AddOfficial from "./official/AddOfficial.vue";
import Advertisment from "./ads/Advertisment.vue";
import RadialProgressBar from "vue-radial-progress";
import { mapState, mapGetters } from "vuex";
import Confirmation from "../manage-clubs/Confirmation";
import { PAGE_NUMBERS } from "../../helpers/constants.js";

export default {
  components: {
    AddRange,
    AddMatch,
    AddOfficial,
    Advertisment,
    RadialProgressBar,
    Confirmation
  },
  name: "Create Match",
  created() {
    this.scrollToScreenTop();
    this.$store.commit("eventModule/resetStatus");
  },
  data() {
    return {
      page: 1,
      isSubmitted: false,
      sponsorSection: false,

      event: {},
      headers: {
        1: {
          title: "Create match",
          next: "Next: Add range"
        },
        2: {
          title: "Add range",
          next: "Next: Add official"
        },
        3: {
          title: "Add official",
          next: "Next: Advertisment"
        },
        4: {
          title: "Advertisment",
          next: "Next: Confirmation"
        },
        5: {
          title: "",
          next: ""
        }
      }
    };
  },
  computed: {
    ...mapState("eventModule", ["status", "matchID"]),
    ...mapGetters({
      userID: "authenticate/getUserId"
    })
  },
  methods: {
    matchAdded(match) {
      this.event = match;
      this.page = PAGE_NUMBERS.RANGE_DETAILS;
    },
    rangeAdded(ranges) {
      this.event.ranges = ranges;
      this.page = PAGE_NUMBERS.OFFICIAL_DETAILS;
    },
    async officialAdded(officials) {
      this.isSubmitted = true;
      this.event.UserID = this.userID;
      this.event.matchOfficials = officials;
      await this.$store.dispatch("eventModule/create", this.event).then(() => {
        this.isSubmitted = false;
        this.sponsorSection = true;
        this.page = PAGE_NUMBERS.SPONSOR_DETAILS;
      });
    },
    async advertAdded() {
      this.isSubmitted = true;
    },
    onBack() {
      if (this.sponsorSection == true) {
        this.$router.push({ name: "club" });
        this.event = {};
      } else {
        if (this.page > PAGE_NUMBERS.MATCH_DETAILS) this.page--;
        else {
          this.$router.push({ name: "club" });
          this.event = {};
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.title-event {
  font-size: 23px;
  font-style: normal;
  font-weight: 700;

  letter-spacing: 1.25px;
  text-align: right;
}

.next {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;

  letter-spacing: 1.25px;
  text-align: right;
}
.pi.pi-arrow-left {
  color: rgba(0, 0, 0, 0.6);
}
</style>