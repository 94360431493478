import { Service } from "./service";

class CategoryService extends Service {
  constructor() {
    super();
  }

  async add(payload, cb, errorCb) {
    await this.https
      .post(`category/AddCategory`, payload) //TODO
      .then((res) => {
        cb(res.data);
      })
      .catch((error) => {
        errorCb(error);
      });
  }

  async update(payload, cb, errorCb) {
    await this.https
      .put(`category/UpdateCategory`, payload)
      .then((res) => {
        cb(res.data);
      })
      .catch((error) => {
        errorCb(error);
      });
  }

  async getCategoriesByClub(clubId, cb, errorCb) {
    await this.https
      .get(`category/GetCategories/${clubId}`)
      .then((res) => {
        cb(res.data);
      })
      .catch((error) => {
        errorCb(error);
      });
  }

  async removeCategory(categoryId, cb, errorCb) {
    await this.https
      .delete(`category/DeleteCategory/${categoryId}`)
      .then((res) => {
        cb(res.data);
      })
      .catch((error) => {
        errorCb(error);
      });
  }
}

export default new CategoryService();
