<template>
  <div class="p-grid centered-container p-d-flex bg-grey-custom">
    <div class="p-col-12 clubBanner" v-bind:style="{ backgroundImage: 'url(' + selectedClub.image + ')', backgroundSize: 'cover'}">
      <div class="p-grid heading" >
        <div class="p-col-2">
          <div class="iconBackground" @click="goBack">
            <i class="pi pi-arrow-left"></i>
          </div>
        </div>
        <div class="p-col-8"></div>      
        <div class="p-col-2 iconBackground editMargin">
          <router-link to="/clubs/update" v-if="is_admin">
            <i class="pi pi-pencil"></i>
          </router-link>
          <router-link to="/clubs/update" v-if="!is_admin">
            <i class="pi pi-eye"></i>
          </router-link>
        </div>
        <div class="p-col-10 p-text-left c-title">
          {{ selectedClub.clubName }}
        </div>
      </div>
    </div>

    <div class="p-col-12 score-content-area p-p-0" v-if="user.isAdmin">
      <div class="p-col-12 p-p-4 content-area icon-red">
        <TabView
          class="p-mt-2"
          :activeIndex="activeTab"
          @tab-change="onTabChange($event)"
        >
        <TabPanel header="Club Admin">
<!-- Club Admin -->
            <!-- Navigate to the Page showing Club admins, Club branches, Target bank and Match category -->
            <avatar-group-with-link
        title="Club Admins"
        :showAdd="is_admin"
        noContentText="No Admins allocated"
        :viewAllCallback="onViewAllAdmins"
        :addCallBack="() => $router.push({ name: 'add-admin' })"
        :imagePaths="clubAdminImages"
      />

      <avatar-group-with-link
        title="Club branches"
        :showAdd="is_admin"
        noContentText="No Branches created"
        :viewAllCallback="onViewAllBranches"
        :addCallBack="() => $router.push({ name: 'create-branch' })"
        :imagePaths="clubBranchImages"
      />

      <avatar-group-with-link
        v-if="is_admin"
        title="Target bank"
        :showAdd="is_admin"
        noContentText="No Targets created"
        :viewAllCallback="onViewAllTargets"
        :addCallBack="() => $router.push({ name: 'create-target' })"
        :imagePaths="clubTargets"
      />

      <!-- Match category -->
      <div class="p-grid p-pt-4" v-if="is_admin">
        <div class="p-col-10 p-text-left p-pl-0 p-pb-3 h-text">
          Match category
        </div>
        <div class="p-col-2 p-text-right">
          <router-link to="/category/create" v-if="is_admin">
            <i class="pi pi-plus"></i>
          </router-link>
        </div>
      </div>
      <div class="p-grid item" v-if="is_admin">
        <div class="p-col-8 p-as-center">
          <AvatarGroup>
            <Avatar
              v-if="selectedClubCategories.length === 0"
              label="0"
              size="large"
              shape="circle"
            />
            <Avatar
              v-for="category in selectedClubCategories.slice(0, 5)"
              :key="category.branchID"
              :label="category.name.substring(0, 2)"
              size="large"
              shape="circle"
            />
          </AvatarGroup>
        </div>

        <div class="p-col-4 p-as-center p-text-right">
          <div class="vertical-container">
            <div class="box" v-if="selectedClubCategories.length > 0">
              <div @click="onViewAllCategories" class="text-primary pointer">
                View all
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Match division -->
      <div class="p-grid p-pt-4" v-if="is_admin">
        <div class="p-col-10 p-text-left p-pl-0 p-pb-3 h-text">
          Match division
        </div>
        <div class="p-col-2 p-text-right">
          <router-link to="/division/create" v-if="is_admin">
            <i class="pi pi-plus"></i>
          </router-link>
        </div>
      </div>
      <div class="p-grid item" v-if="is_admin">
        <div class="p-col-8 p-as-center">
          <AvatarGroup>
            <Avatar
              v-if="selectedClubDivisions.length === 0"
              label="0"
              size="large"
              shape="circle"
            />
            <Avatar
              v-for="division in selectedClubDivisions.slice(0, 5)"
              :key="division.branchID"
              :label="division.name.substring(0, 2)"
              size="large"
              shape="circle"
            />
          </AvatarGroup>
        </div>

        <div class="p-col-4 p-as-center p-text-right">
          <div class="vertical-container">
            <div class="box" v-if="selectedClubDivisions.length > 0">
              <div @click="onViewAllDivisions" class="text-primary pointer">
                View all
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- ------------- -->
        </TabPanel>
  
          <TabPanel header="Matches">
           
<!-- matches -->
    



    
      <div class="p-grid p-pt-4">
        <p class="h-text label-margin" v-if="is_admin">Click the plus button to add a match</p>
        <div class="p-col-10 p-text-left p-pl-0 p-pb-3 h-text">Matches</div>
        <div class="p-col-2 p-text-right">
          <router-link to="/events/matchinformation" v-if="is_admin">
            <i class="pi pi-plus"></i>
          </router-link>
        </div>
      </div>
      <div class="p-col-12" v-if="getMatchesByClubStatus === 'loading'">
        <ProgressBar mode="indeterminate" class="progress" />
      </div>

      <div class="p-grid p-pb-4">
        <TabView>
          <TabPanel header="Upcoming">
            <div class="p-col-12" v-if="updcomingMatches.length < 1">
              No events.
            </div>
            <div v-for="match in updcomingMatches" :key="match.ID">
              <match-item
                :match="match"
                :dateFilter="constants.DATE_FILTER.UPCOMING"
              />
            </div>
          </TabPanel>
          <TabPanel header="In-progress ">
            <div class="p-col-12" v-if="inProgressMatches.length < 1">
              No events.
            </div>
            <div v-for="match in inProgressMatches" :key="match.ID">
              <match-item
                :match="match"
                :dateFilter="constants.DATE_FILTER.ONGOING"
              />
            </div>
          </TabPanel>
          <TabPanel header="Completed">
            <div class="p-col-12" v-if="completedMatches.length < 1">
              No events.
            </div>

            <div v-for="match in completedMatches" :key="match.ID">
              <match-item
                :match="match"
                :dateFilter="constants.DATE_FILTER.COMPLETED"
              />
            </div>
          </TabPanel>
        </TabView>
      </div>
          </TabPanel>

        </TabView>
      </div>
    </div>



  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import MatchItem from "../events/match/MatchItem.vue";
import AvatarGroupWithLink from '../shared/AvatarGroupWithLink.vue';

export default {
  name: "club",
  components: { MatchItem, AvatarGroupWithLink },
  created() {
    this.$store.dispatch("eventModule/matchesForClub", {
      clubId: this.selectedClub.clubID,
      userId: this.user.id,
    });
    this.$store.dispatch(
      "branchModule/branchesForClub",
      this.selectedClub.clubID
    );

    this.$store.dispatch(
      "targetModule/targetsForClub",
      this.selectedClub.clubID
    );

    this.$store.dispatch(
      "categoryModule/categoriesForClub",
      this.selectedClub.clubID
    );

    this.$store.dispatch(
      "divisionModule/divisionsForClub",
      this.selectedClub.clubID
    );

    this.$store.dispatch(
      "clubModule/clubAdminUsers",
      this.selectedClub.clubID
    );
  },
  data() {
    return {
      today: new Date(),
    };
  },
  computed: {
    ...mapGetters({
      clubTargets: "targetModule/getClubTargets",
      clubBranchImages: "branchModule/getClubBranchesImages",
      clubAdminImages: "clubModule/getClubAdminImages"
    }),
    ...mapState("clubModule", ["approvedClubs", 
    "selectedClub", 
    "selectedClubAdmins"]),
    
    ...mapState("eventModule", [
      "getMatchesByClubStatus",
      "selectedClubMatches",
    ]),
    ...mapState("categoryModule", ["selectedClubCategories"]),
    ...mapState("branchModule", ["selectedClubBranches"]),
    ...mapState("targetModule", ["selectedClubTargets"]),
    ...mapState("divisionModule", ["selectedClubDivisions"]),
    ...mapState("authenticate", ["user", "is_admin"]),

    updcomingMatches() {
      return this.selectedClubMatches.filter(
        (m) => new Date(m.startDate) >= this.today
      );
    },
    inProgressMatches() {
      return this.selectedClubMatches.filter(
        (m) =>
           this.today >=new Date(m.startDate) && this.today <= new Date(m.endDate)
      );
    },
    completedMatches() {
      return this.selectedClubMatches.filter(
        (m) => new Date(m.endDate) < this.today
      );
    },
  },
  methods: {
    onViewAllBranches() {
      this.$router.push({ name: "branches" });
    },
    onViewAllTargets() {
      this.$router.push({ name: "targets" });
    },
    onViewAllCategories() {
      this.$router.push({ name: "categories" });
    },
    onViewAllDivisions() {
      this.$router.push({ name: "divisions" });
    },
    onViewAllAdmins() {
      this.$router.push({ name: "admins" });
    },
    goBack() {
      this.$store.commit("clubModule/updateSelectedClub", {});
      this.$router.push({ name: "clubs" });
    },
  },
};
</script>

<style lang="scss" scoped>
:deep .p-selectbutton .p-button {
  background: #ededed;
  border-radius: 20px;
}

:deep .p-selectbutton .p-button.p-highlight {
  border-radius: 20px;
}

:deep .p-selectbutton .p-button {
  border: none;
  transition: background-color 0s, color 0s, border-color 0s, box-shadow 0s;
}

:deep .p-tabview .p-tabview-panels {
  background: transparent;
  padding: 0px;
}

:deep .p-tabview .p-tabview-nav {
  background: transparent;
  border: none;
  font-size: 13px;
  justify-content: center;
}

:deep .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: transparent;
  border-color: transparent;
}

:deep .p-tabview .p-tabview-nav li .p-tabview-nav-link {
  background: transparent;
}

.p-fluid .p-buttonset {
  background: #ededed;
  border-radius: 20px;
}

.c-title {
  font-weight: bold;
  font-size: 15px;
  line-height: 14px;
  align-items: center;
  letter-spacing: 1.25px;
  color: #ffffff;
  margin-top:150px;
}

.clubBanner{
  height: 250px;
}
.content-area.icon-red {
    margin-top: -20px;
    z-index: 1;
}
.pi.pi-arrow-left
 {
    margin-top: 10px;
 }
.iconBackground {
  border-radius: 50%;
  background: #2c3e50;
  height: 35px;
  width: 35px;
}
.editMargin {
  margin-top: 5px;
  margin-left: 15px;
}

.label-margin {
  margin:0;
}
</style>