import { Service } from "./service";

class TargetService extends Service {
  constructor() {
    super();
  }
  async add(payload, cb, errorCb) {
    await this.https
      .post(`targets/AddTarget`, payload)
      .then((res) => {
        cb(res.data);
      })
      .catch((error) => {
        errorCb(error);
      });
  }

  async update(payload, cb, errorCb) {
    await this.https
      .post(`targets/UpdateTarget`, payload)
      .then((res) => {
        cb(res.data);
      })
      .catch((error) => {
        errorCb(error);
      });
  }

  async getTargets(cb, errorCb) {
    await this.https
      .get("targets")
      .then((res) => {
        cb(res.data);
      })
      .catch((error) => {
        errorCb(error);
      });
  }

  async getTargetsByClub(clubId, cb, errorCb) {
    await this.https
      .get(`targets/ViewTargetLinkedToClub/${clubId}`)
      .then((res) => {
        cb(res.data);
      })
      .catch((error) => {
        errorCb(error);
      });
  }

  async removeTarget(targetId, cb, errorCb) {
    await this.https
      .delete(`targets/DeleteTarget/${targetId}`)
      .then((res) => {
        cb(res.data);
      })
      .catch((error) => {
        errorCb(error);
      });
  }
}

export default new TargetService();
