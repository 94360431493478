import { Service } from "./service";

class CaptureScoreService extends Service {
  constructor() {
    super();
  }

  async rangesForMarch(matchId, cb, errorCb) {
    await this.https
      .get(`range/AssignedMatchesRanges/${matchId}`)
      .then((res) => {
        cb(res.data);
      })
      .catch((error) => {
        errorCb(error);
      });
  }

  async rangeForParticipant(payload, cb, errorCb) {
    await this.https
      .get(`matchScore/ParticipantRanges/${payload.UserId}/${payload.MatchID}`)
      .then((res) => {
        cb(res.data);
      })
      .catch((error) => {
        errorCb(error);
      });
  }

  async userByParticipantNo(payload, cb, errorCb) {
    await this.https
      .get(`matchScore/SearchParticipant/${payload.participantNo}/${payload.matchId}`)
      .then((res) => {
        cb(res.data);
      })
      .catch((error) => {
        errorCb(error);
      });
  }

  async matchRangeScoreList(payload, cb, errorCb) {
    await this.https
      .get(`matchScore/MatchScoresPerRange/${payload.MatchId}/${payload.RangeID}`)
      .then((res) => {
        cb(res.data);
      })
      .catch((error) => {
        errorCb(error);
      });
  }

  async matchScoresPerRangePerUser(payload, cb, errorCb) {
    await this.https
      .get(`matchScore/MatchScoresPerRangePerUser/${payload.MatchId}/${payload.RangeID}/${payload.UserId}`)
      .then((res) => {
        cb(res.data);
      })
      .catch((error) => {
        errorCb(error);
      });
  }

  async editScore(payload, cb, errorCb) {
    await this.https
      .put(`matchScore/EditParticipantScore/${payload.matchScoreID}/${payload.score}`)
      .then((res) => {
        cb(res.data);
      })
      .catch((error) => {
        errorCb(error);
      });
  }

  async captureScore(payload, cb, errorCb) {
    await this.https
      .post(`matchScore/CaptureParticipantScore`, payload)
      .then((res) => {
        cb(res.data);
      })
      .catch((error) => {
        errorCb(error);
      });
  }

  async deleteScore(matchScoreID, cb, errorCb) {
    await this.https
      .delete(`MatchScore/DeleteParticipantScore/${matchScoreID}`)
      .then((res) => {
        cb(res.data);
      })
      .catch((error) => {
        errorCb(error);
      });
  }

  async MatchList(payload, cb, errorCb) {
    await this.https
    .get(`matchScore/OfficialClubMatches/${payload.UserId}`)
      .then((res) => {
        cb(res.data);
      })
      .catch((error) => {
        errorCb(error);
      });
  }

  async MyMatchList(payload, cb, errorCb) {
    await this.https
    .get(`matchScore/ClubMatchesForParticipant/${payload.UserId}`)
      .then((res) => {
        cb(res.data);
      })
      .catch((error) => {
        errorCb(error);
      });
  }

  async overalScoreBoard(matchId, cb, errorCb) {
    await this.https
      .get(`matchScore/MyScoreBoard/${matchId}`)
      .then((res) => {
        cb(res.data);
      })
      .catch((error) => {
        errorCb(error);
      });
  }

  async divisionScoreBoard(matchId, cb, errorCb) {
    await this.https
      .get(`matchScore/MyScoreBoardDivision/${matchId}`)
      .then((res) => {
        cb(res.data);
      })
      .catch((error) => {
        errorCb(error);
      });
  }

  async categoryScoreBoard(matchId, cb, errorCb) {
    await this.https
      .get(`matchScore/MyScoreBoardCategory/${matchId}`)
      .then((res) => {
        cb(res.data);
      })
      .catch((error) => {
        errorCb(error);
      });
  }


  async MyScores(payload, cb, errorCb) {
    await this.https
    .get(`matchScore/MyScoresPerRange/${payload.MatchId}/${payload.UserId}`)
      .then((res) => {
        cb(res.data);
      })
      .catch((error) => {
        errorCb(error);
      });
  }

  async EditScoreValidation(payload, cb, errorCb) {
    await this.https
    .get(`matchScore/EditScoreValidation/${payload.MatchId}/${payload.UserId}`)
      .then((res) => {
        cb(res.data);
      })
      .catch((error) => {
        errorCb(error);
      });
  }
}

export default new CaptureScoreService();
