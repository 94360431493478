<template>
  <div class="p-fluid p-grid p-formgrid p-ai-start vertical-container p-m-0">
    <div class="p-col-12">
      <AutoComplete
        :multiple="true"
        v-model="selectedOfficials"
        :suggestions="searchUserList"
        placeholder="New official email"
        @complete="onEmailChange($event)"
        @item-select="onSelect"
        @item-unselect="unSelect"
        field="email"
      />
    </div>
        <p class="error-message" v-if="!isOfficialSelected">
      * Atleast one official is required
    </p>
    <div class="p-field p-col-12 p-pt-4">
      <Button
        label="Next"
        class="p-button-raised p-button-rounded p-px-2 p-m-0"
        @click="onNext"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "AddOfficial",
  created() {
    for (var i in this.selectedMatch.matchOfficial) {
      this.selectedOfficials.push({
        email: this.selectedMatch.matchOfficial[i].email
      });
    }
    this.isOfficialSelected = true;
  },
  data() {
    return {
      filteredList: null,
      selectedOfficials: [],
      email: "",
      query: "",
      isOfficialSelected: false
    };
  },
  computed: {
    ...mapState("authenticate", ["searchStatus", "searchUserList"]),
    ...mapState("eventModule", ["selectedMatch"])
  },
  methods: {
    async onEmailChange(event) {
      if (event.query.length >= 3 && event.query !== this.query) {
        setTimeout(() => {
          this.getUsers(event.query);
        }, 500);
      }
    },

    async getUsers(query) {
      await this.$store.dispatch("authenticate/userSearch", query);
      this.query = query;
    },

    onNext() {
      const entries = this.selectedOfficials.map(o => ({
        email: o.email
      }));
      if (entries.length > 0) this.$emit("update-officials", entries);
    },
    onSelect() {
      if (this.selectedOfficials != 0) this.isOfficialSelected = true;
    },
    unSelect() {
      if (this.selectedOfficials == 0) this.isOfficialSelected = false;
    }
  }
};
</script>

<style lang="scss" scoped>
</style>