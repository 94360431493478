<template>
  <div class="navbar-desktop">
    <Menubar :model="items">
      <template #start>
        <img
          alt="logo"
          src="../../assets/logo-black.png"
          height="45"
          class="mr-2 nav-bar-logo"
        />
      </template>
          
    <template #end>
        <a to="/register" class="p-menuitem-link" role="menuitem"><span class="p-menuitem-icon"></span><span class="p-menuitem-text">REGISTER</span><span class="p-ink" style="height: 133px; width: 133px; top: -60.7969px; left: 4.17969px;"></span></a>
    </template>
    </Menubar>
  </div>
</template>

<script>
import Menubar from "primevue/menubar";

export default {
  name: "navbar-desktop",
  components: { Menubar },
  data() {
    return {
      items: [
        {
          label: "CLUBS",
          to: "/find-clubs"
        },
        {
          label: "MATCHES",
          to: "/matches"
        },
        {
          label: "FAQ",
          to: "/faq"
        },
        {
          label: "LOGIN",
          to: "/login"
        },
        {
          label: "REGISTER",
          to: "/register"
        },
      ],
    };
  },
};
</script>

<style scoped>
.navbar-desktop {
  padding-left: 2rem;
  position: relative;
  background: #8b0e0f;
  border-color: #8b0e0f;
}
.nav-bar-logo {
  padding-right: 4rem;
  position: relative;
  left: 2rem;
  /* right: 87.97%;
  top: 0.83%;
  bottom: 94.74%; */
  transform: scale(1.5,1.5);
}
.p-menubar {
  /* Changed this to smaller navbar */
  padding: .8rem; 
  background: transparent;
  color: white;
  border: 0px solid #e5e5e5;
  border-radius: 0px;
  position: relative;
  align-content: right;
  justify-items: right;
  /* left: 0px;
  top: 0px; */
}

</style>
