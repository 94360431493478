<template>
    <login-web-vue v-if="!isMobile()"/>
    <login-mobile-vue v-if="isMobile()"/>
</template>

<script>
import LoginWebVue from './LoginWeb.vue';
import LoginMobileVue from './LoginMobile.vue';
export default {
  name: "login",
  components: { LoginWebVue, LoginMobileVue },
  methods: {
    isMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

