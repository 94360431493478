import { Service } from "./service";

class EventService extends Service {
  constructor() {
    super();
  }

  async add(payload, cb, errorCb) {
    await this.https
      .post("match/AddMatch ", payload)
      .then((res) => {
        cb(res.data);
      })
      .catch((error) => {
        errorCb(error);
      });
  }

  async update(payload, cb, errorCb) {
    await this.https
      .put(`match/UpdateMatch`, payload)
      .then((res) => {
        cb(res.data);
      })
      .catch((error) => {
        errorCb(error);
      });
  }

  async getMatchesByClub(payload, cb, errorCb) {
    await this.https
      .get(`match/club/${payload.clubId}/${payload.userId}`)
      .then((res) => {
        cb(res.data);
      })
      .catch((error) => {
        errorCb(error);
      });
  }

  async viewMatchesPerClub(payload) {
    return await this.https.get(`Match/ViewMatchesPerClub/${payload.userID}/${payload.skip}/${payload.matchName}`);
  }

  async getMatch(matchId, cb, errorCb) {
    await this.https
      .get(`match/match/${matchId}`)
      .then((res) => {
        cb(res.data);
      })
      .catch((error) => {
        errorCb(error);
      });
  }

  async joinMatch(payload, cb, errorCb) {
    await this.https
      .post(`match/ParticipantJoinMatch`, payload)
      .then((res) => {
        cb(res.data);
      })
      .catch((error) => {
        errorCb(error);
      });
  }

  async assignedMatches(payload, cb, errorCb) {
    await this.https
      .get(`match/Official/AssignedMatches/${payload.userId}/${payload.skip}`)
      .then((res) => {
        cb(res.data);
      })
      .catch((error) => {
        errorCb(error);
      });
  }

  async rangeDetails(rangeId, cb, errorCb) {
    await this.https
      .get(`range/GetRangeDetails/${rangeId}`)
      .then((res) => {
        cb(res.data);
      })
      .catch((error) => {
        errorCb(error);
      });
  }

  async updateRange(payload, cb, errorCb) {
    await this.https
      .put(`range/UpdateRange`, payload)
      .then((res) => {
        cb(res.data);
      })
      .catch((error) => {
        errorCb(error);
      });
  }

  async deleteRange(rangeId, cb, errorCb) {
    await this.https
      .delete(`Range/DeleteRange/${rangeId}`)
      .then((res) => {
        cb(res.data);
      })
      .catch((error) => {
        errorCb(error);
      });
  }

  async getSponsorsDetails(matchId, cb, errorCb) {
    await this.https
      .get(`Sponsor/GetSponsorForMatch/${matchId}`)
      .then((res) => {
        cb(res.data);
      })
      .catch((error) => {
        errorCb(error);
      });
  }

  async deleteSponsor(sponsorId, cb, errorCb) {
    await this.https
      .delete(`Sponsor/DeleteSponsor/${sponsorId}`)
      .then((res) => {
        cb(res.data);
      })
      .catch((error) => {
        errorCb(error);
      });
  }

  async officialMatchesToday(userId) {
    return await this.https.get(`match/OfficialMatchesToday/${userId}`);
  }

  async participantMatchesToday(userId) {
    return await this.https.get(`match/ParticipantMatchesToday/${userId}`);
  }

  async myClubMatches(userId) {
    return await this.https.get(`match/MyClubsMatches/${userId}`);
  }

  async adminMatchesToday(userId) {
    return await this.https.get(`match/AdminMatchesToday/${userId}`);
  }

  async linkSponsor(payload, cb, errorCb) {
    await this.https
      .post("sponsor/AddSponsor ", payload)
      .then((res) => {
        cb(res.data);
      })
      .catch((error) => {
        errorCb(error);
      });
  }

  async getRegisteredMatchesById(userId) {
    return this.https
      .get(`match/Participant/RegisteredMatches/${userId}`);
  }
}

export default new EventService();
