<template>
  <div class="p-fluid p-grid p-formgrid p-ai-start vertical-container p-m-0">
    <Toast position="top-center toast-width" group="br" class="toast" />
    <div class="p-col-12 p-p-2" v-for="range in list" :key="range.name">
      <Range :item="range"></Range>
    </div>
    <div class="p-col-12" v-if="isAddNew">
      <div class="box">
        <div class="p-fluid p-grid p-formgrid p-text-left">
          <div class="p-field p-col-8 p-pt-2 h-text">Range details</div>
          <div class="p-field p-col-12 p-pt-2">
            <span class="p-float-label">
              <InputText
                id="range-name"
                type="text"
                v-model="range.name"
                :class="{ 'p-invalid': v$.range.name.required.$invalid }"
              />
              <label for="inputtext-left">Range name</label>
            </span>
            <p class="error-message" v-if="v$.range.name.required.$invalid">* Range name is required</p>
          </div>
          <div class="p-field p-col-12 p-pt-3">
            <span class="p-float-label">
              <Dropdown
                id="targetID"
                v-model="range.targetID"
                :options="selectedClubTargets"
                optionValue="targetID"
                optionLabel="name"
                :class="{ 'p-invalid': v$.range.targetID.required.$invalid }"
              />
              <label for="inputtext-left">Target</label>
            </span>
            <p class="error-message" v-if="v$.range.targetID.required.$invalid">* Target is required</p>
          </div>
          <div class="p-field p-col-12 p-pt-3">
            <div class="p-grid">
              <div class="p-col-6">
                <input
                  type="radio"
                  id="Time based"
                  name="rangeType"
                  value="rangeType"
                  @click="timeBased()"
                />
                <label for="Time based">Time based</label>
                <br />
              </div>
              <div class="p-col-6">
                <input
                  type="radio"
                  id="Score based"
                  name="rangeType"
                  value="rangeType"
                  @click="scoreBased()"
                />
                <label for="Score based">Score based</label>
                <br />
              </div>
            </div>
          </div>
          <div class="p-field p-col-12 p-pt-3" v-if="!scoreType">
            <span class="p-float-label">
              <InputNumber id="maxScore" v-model="range.targetScore" />
              <label for="inputtext-left">Target max score</label>
            </span>
            <p
              class="error-message"
              v-if="range.targetScore === undefined || range.targetScore === null"
            >* Max score is required</p>
          </div>
          <div class="p-field p-col-12 p-pt-3">
            <span class="p-float-label">
              <InputNumber
                id="rounds"
                v-model="range.roundCount"
                :class="{ 'p-invalid': v$.range.roundCount.required.$invalid }"
              />
              <label for="inputtext-left">Round count</label>
            </span>
            <p
              class="error-message"
              v-if="v$.range.roundCount.required.$invalid"
            >* Round count is required</p>
          </div>
          <div class="p-field p-col-12 p-pt-3">
            <Textarea
              placeholder="Range description"
              v-model="range.rangeDescription"
              :class="{
                'p-invalid': v$.range.rangeDescription.required.$invalid
              }"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
          <p
            class="error-message"
            v-if="v$.range.rangeDescription.required.$invalid"
          >* Range description is required</p>
        </div>
      </div>
    </div>
    <div class="p-col-12 p-text-left icon-red p-pt-4" @click="onAdd">
      <div class="next">
        <div v-if="!isAddNew">
          <i class="pi pi-plus pointer"></i>
          Add new range
        </div>
        <Button label="CANCEL" class="p-button-text p-px-2 p-m-0" v-if="isAddNew"></Button>
      </div>
    </div>
    <div class="p-field p-col-12 p-pt-4">
      <Button
        :label="buttonTItle"
        class="p-button-raised p-button-rounded p-px-2 p-m-0"
        @click="onSaveRange"
      />
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { mapState } from "vuex";
import Range from "./Range.vue";

const SAVE = "SAVE";
const NEXT = "NEXT";

export default {
  components: {
    Range
  },
  name: "UpdateRange",
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  created() {
    this.$store.dispatch(
      "targetModule/targetsForClub",
      this.selectedClub.clubID
    );

    for (var i in this.selectedMatch.ranges) {
      this.list.push({
        rangeID: this.selectedMatch.ranges[i].rangeID,
        isScoringTimeBased: true,
        name: this.selectedMatch.ranges[i].name,
        roundCount: this.selectedMatch.ranges[i].roundCount,
        targetID: this.selectedMatch.ranges[i].targetID,
        targetName: this.selectedMatch.ranges[i].targetName,
        targetScore: this.selectedMatch.ranges[i].targetScore,
        rangeDescription: this.selectedMatch.ranges[i].rangeDescription,
        updated: true
      });
    }
  },
  data() {
    return {
      isAddNew: false,
      scoreType: false,
      list: [],
      newRangeList: [],
      range: {
        name: "",
        targetID: "",
        isScoringTimeBased: {
          name: "Time based",
          value: true
        },
        targetScore: 0,
        roundCount: 0,
        updated: false,
        rangeDescription: ""
      }
    };
  },
  validations: {
    range: {
      name: {
        required,
        $lazy: true
      },
      targetID: {
        required,
        $lazy: true
      },
      isScoringTimeBased: {
        required,
        $lazy: true
      },
      roundCount: {
        required,
        $lazy: true
      },
      rangeDescription: {
        required,
        $lazy: true
      }
    }
  },
  computed: {
    ...mapState("clubModule", ["selectedClub"]),
    ...mapState("targetModule", ["selectedClubTargets"]),
    ...mapState("eventModule", ["status", "matchID", "selectedMatch"]),
    buttonTItle() {
      return this.isAddNew ? SAVE : NEXT;
    }
  },
  methods: {
    onAdd() {
      this.isAddNew = !this.isAddNew;
      this.v$.$reset();
    },
    timeBased() {
      this.scoreType = true;
    },
    scoreBased() {
      this.scoreType = false;
    },
    async onSaveRange() {
      if (this.isAddNew) {
        this.v$.$touch();
        if (!this.v$.$invalid) {
          this.range.isScoringTimeBased = this.scoreType;
          this.list.push(this.range);
          this.newRangeList.push(this.range);
          this.range = {};
          this.isAddNew = false;
        }
      } else {
        if (this.list.length > 0 || this.newRangeList > 0) {
          this.$emit("update-range-list", this.newRangeList);
        } else {
          this.$toast.add({
            severity: "error",
            summary:
              "An error has occured. Please ensure you have added at least one range.",
            group: "br",
            life: 2000
          });
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.toast-width {
  width: 320px;
}
</style>