<template>
   <div class="p-grid background">
      <div class="p-col-12">
         <div class="p-grid heading">
            <div class="p-col-2">
               <router-link to="/club">
                  <i class="pi pi-arrow-left"></i>
               </router-link>
            </div>
            <div class="p-col-8">
               <HeaderLogo/>
               <h4>Club branches</h4>
            </div>
            <div class="p-col-2">
               <router-link to="/branches/create" v-if="is_admin">
                  <i class="pi pi-plus"></i>
               </router-link>
            </div>
         </div>
      </div>
      <div class="p-col-12 p-pb-4 search">
         <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText type="text" v-model="search" placeholder="Search by branch name " />
         </span>
      </div>
      <div class="p-col-12 p-px-2 p-py-4 content-area">
         <div class="p-col-12" v-if="branchesFiltered.length === 0">No branches found</div>
         <div class="p-col-12" v-for="b in branchesFiltered" :key="b.clubID">
            <branch-item :branch="b" />
         </div>
      </div>
      <Toast position="center" />
   </div>
</template>

<script>
import { mapState } from "vuex";
import BranchItem from "./BranchItem.vue";
import HeaderLogo from "../../shared/HeaderLogo.vue"
export default {
  name: "Branches",
  components: { BranchItem, HeaderLogo },
  created() {
    this.$store.dispatch(
      "branchModule/branchesForClub",
      this.selectedClub.clubID
    );
  },
  data() {
    return { search: "" };
  },
  computed: {
    ...mapState("clubModule", ["selectedClub"]),
    ...mapState("branchModule", ["selectedClubBranches"]),

    branchesFiltered() {
      return this.selectedClubBranches.filter(
        (a) =>
          this.search === "" ||
          a.branchName.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
:deep .search {
  .p-input-filled .p-inputtext {
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
  }
  .p-inputtext {
    border-radius: 30px;
  }
}
 :deep {
   .p-avatar-image.p-avatar-xl {
    width: 100%;
    height: 100%;
    font-size: 2rem;
     img {
      width: auto;
      height: 100%;
      border-radius: 5px;
    }
  }
  .vertical-container .p-avatar.p-component img {
      width: 100%;
      height: 140px;
      height: 150px;
      border-radius: 5px;
      object-fit: cover;
  } 
  .pic, .pic-x {
      background: none;
    }
 }
 
</style>