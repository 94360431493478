<template>
  <div class="card register-card">

    <div class="" style="text-align: left; color:white">
        <!-- <label class="info-section-icons-text">FIND CLUBS </label> -->
         <svg width="12" height="12" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H10.6667V13.3333H0V0ZM24 0H13.3333V8H24V0ZM8 10.6667V2.66667H2.66667V10.6667H8ZM21.3333 5.33333V2.66667H16V5.33333H21.3333ZM21.3333 13.3333V21.3333H16V13.3333H21.3333ZM8 21.3333V18.6667H2.66667V21.3333H8ZM24 10.6667H13.3333V24H24V10.6667ZM0 16H10.6667V24H0V16Z" fill="white"/>
         </svg>

        <span class=""> Dashboard</span>

<Divider />
    </div>


    <div class="p-col-12 p-pt-4">
      <Splitter class="p-pt-4 info-section">
        <SplitterPanel>
          <Splitter layout="vertical" class="splitter-color">
            <SplitterPanel :size="100">
              <Splitter class="splitter-color">
                <SplitterPanel :size="20" style="text-align: left; margin: 20px">
                  <div class="region">
                    <h4 class="">Filter by region </h4>
                   <div class="field-checkbox">
                    <div class="p-d-flex">
                        <div class="p-mr-2">  
                        <Checkbox id="city1" name="region" value="Gauteng" v-model="regions" />
                        <label for="city1">Gauteng</label></div>
                        <Chip label="10" style="font-size:9px" />
                    </div>
                   </div>

                   <div class="field-checkbox">
                    <div class="p-d-flex">
                        <div class="p-mr-2">  
                        <Checkbox id="city1" name="region" value="Western Cape" v-model="regions" />
                        <label for="city1">Western Cape</label></div>
                        <Chip label="10" style="font-size:9px" />
                    </div>
                   </div>

                                      <div class="field-checkbox">
                    <div class="p-d-flex">
                        <div class="p-mr-2">  
                        <Checkbox id="city1" name="region" value="KwaZulu-Natal" v-model="regions" />
                        <label for="city1">KwaZulu-Natal</label></div>
                        <Chip label="10" style="font-size:9px" />
                    </div>
                   </div>

                                      <div class="field-checkbox">
                    <div class="p-d-flex">
                        <div class="p-mr-2">  
                        <Checkbox id="city1" name="region" value="Mpumalanga" v-model="regions" />
                        <label for="city1">Mpumalanga</label></div>
                        <Chip label="10" style="font-size:9px" />
                    </div>
                   </div>

                                      <div class="field-checkbox">
                    <div class="p-d-flex">
                        <div class="p-mr-2">  
                        <Checkbox id="city1" name="region" value="Eastern Cape" v-model="regions" />
                        <label for="city1">Eastern Cape</label></div>
                        <Chip label="10" style="font-size:9px" />
                    </div>
                   </div>

                                                      <div class="field-checkbox">
                    <div class="p-d-flex">
                        <div class="p-mr-2">  
                        <Checkbox id="city1" name="region" value="Northern Cape" v-model="regions" />
                        <label for="city1">Northern Cape</label></div>
                        <Chip label="10" style="font-size:9px" />
                    </div>
                   </div>

                                                         <div class="field-checkbox">
                    <div class="p-d-flex">
                        <div class="p-mr-2">  
                        <Checkbox id="city1" name="region" value="Free State" v-model="regions" />
                        <label for="city1">Free State</label></div>
                        <Chip label="10" style="font-size:9px" />
                    </div>
                   </div>

                                                         <div class="field-checkbox">
                    <div class="p-d-flex">
                        <div class="p-mr-2">  
                        <Checkbox id="city1" name="region" value="North West" v-model="regions" />
                        <label for="city1">North West</label></div>
                        <Chip label="10" style="font-size:9px" />
                    </div>
                   </div>

                                                         <div class="field-checkbox">
                    <div class="p-d-flex">
                        <div class="p-mr-2">  
                        <Checkbox id="city1" name="region" value="Limpopo" v-model="regions" />
                        <label for="city1">Limpopo</label></div>
                        <Chip label="10" style="font-size:9px" />
                    </div>
                   </div>
                  </div>
     
                  <div class="category">
                    <h4 class="">Filter by match category</h4>
                   <div class="field-checkbox">
                    <div class="p-d-flex">
                        <div class="p-mr-2">  
                        <Checkbox id="city1" name="region" value="Men" v-model="regions" />
                        <label for="city1">Men</label></div>
                        <Chip label="13" style="font-size:9px" />
                    </div>
                   </div>

                   <div class="field-checkbox">
                    <div class="p-d-flex">
                        <div class="p-mr-2">  
                        <Checkbox id="city1" name="region" value="Women" v-model="regions" />
                        <label for="city1">Women</label></div>
                        <Chip label="10" style="font-size:9px" />
                    </div>
                   </div>

                                      <div class="field-checkbox">
                    <div class="p-d-flex">
                        <div class="p-mr-2">  
                        <Checkbox id="city1" name="region" value="Children" v-model="regions" />
                        <label for="city1">Children</label></div>
                        <Chip label="6" style="font-size:9px" />
                    </div>
                   </div>

                    
                  </div>
                  
                  <div class="city">
                    <h4 class="">Filter by city </h4>
                   <div class="field-checkbox">
                    <div class="p-d-flex">
                        <div class="p-mr-2">  
                        <Checkbox id="city1" name="region" value="Johannesburg" v-model="regions" />
                        <label for="city1">Johannesburg</label></div>
                        <Chip label="10" style="font-size:9px" />
                    </div>
                   </div>

                   <div class="field-checkbox">
                    <div class="p-d-flex">
                        <div class="p-mr-2">  
                        <Checkbox id="city1" name="region" value="Cape Town" v-model="regions" />
                        <label for="city1">Cape Town</label></div>
                        <Chip label="10" style="font-size:9px" />
                    </div>
                   </div>

                                      <div class="field-checkbox">
                    <div class="p-d-flex">
                        <div class="p-mr-2">  
                        <Checkbox id="city1" name="region" value="Durban" v-model="regions" />
                        <label for="city1">Durban</label></div>
                        <Chip label="10" style="font-size:9px" />
                    </div>
                   </div>

                                      <div class="field-checkbox">
                    <div class="p-d-flex">
                        <div class="p-mr-2">  
                        <Checkbox id="city1" name="region" value="Gqeberha" v-model="regions" />
                        <label for="city1">Gqeberha</label></div>
                        <Chip label="10" style="font-size:9px" />
                    </div>
                   </div>

                                      <div class="field-checkbox">
                    <div class="p-d-flex">
                        <div class="p-mr-2">  
                        <Checkbox id="city1" name="region" value="Pretoria" v-model="regions" />
                        <label for="city1">Pretoria</label></div>
                        <Chip label="10" style="font-size:9px" />
                    </div>
                   </div>

                                                      <div class="field-checkbox">
                    <div class="p-d-flex">
                        <div class="p-mr-2">  
                        <Checkbox id="city1" name="region" value="Knysna" v-model="regions" />
                        <label for="city1">Knysna</label></div>
                        <Chip label="10" style="font-size:9px" />
                    </div>
                   </div>

                                                         <div class="field-checkbox">
                    <div class="p-d-flex">
                        <div class="p-mr-2">  
                        <Checkbox id="city1" name="region" value="Bloemfontein" v-model="regions" />
                        <label for="city1">Bloemfontein</label></div>
                        <Chip label="10" style="font-size:9px" />
                    </div>
                   </div>

                                                         <div class="field-checkbox">
                    <div class="p-d-flex">
                        <div class="p-mr-2">  
                        <Checkbox id="city1" name="region" value="East London" v-model="regions" />
                        <label for="city1">East London</label></div>
                        <Chip label="10" style="font-size:9px" />
                    </div>
                   </div>

                                                         <div class="field-checkbox">
                    <div class="p-d-flex">
                        <div class="p-mr-2">  
                        <Checkbox id="city1" name="region" value="Polokwane" v-model="regions" />
                        <label for="city1">Polokwane</label></div>
                        <Chip label="10" style="font-size:9px" />
                    </div>
                   </div>

                                                                            <div class="field-checkbox">
                    <div class="p-d-flex">
                        <div class="p-mr-2">  
                        <Checkbox id="city1" name="region" value="Kimberley" v-model="regions" />
                        <label for="city1">Kimberley</label></div>
                        <Chip label="10" style="font-size:9px" />
                    </div>
                   </div>

                                                                            <div class="field-checkbox">
                    <div class="p-d-flex">
                        <div class="p-mr-2">  
                        <Checkbox id="city1" name="region" value="Plettenberg Bay" v-model="regions" />
                        <label for="city1">Plettenberg Bay</label></div>
                        <Chip label="10" style="font-size:9px" />
                    </div>
                   </div>
                  </div>
                </SplitterPanel>
                
                <SplitterPanel :size="20"
                  class="flex align-items-center justify-content-center"
                >
                  <div class="p-grid app" style="margin-bottom:20px">
                      <div class="col" v-for="index in itemCount" :key="index">
                        <club-search-item/>
                      </div>

                  </div>
                </SplitterPanel>

                <SplitterPanel :size="60"
                  class="flex align-items-right justify-content-right"
                >
                  <div class="p-grid app">
                     <maps-component/>
                    <div class="col" >
                    </div>
                  </div>
                </SplitterPanel>

              </Splitter>
            </SplitterPanel>

          </Splitter>
        </SplitterPanel>
      </Splitter>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required, email, minLength } from "@vuelidate/validators";
import CameraIcon from "../../assets/icons/camera-icon.svg";
import MapsComponent from './MapsComponent.vue';
import Chip from 'primevue/chip';
import Divider from 'primevue/divider';

// import ClubItemCardVue from './ClubItemCard.vue';
import ClubSearchItem from './ClubSearchItem.vue';
export default {
  components: { MapsComponent, ClubSearchItem, Chip, Divider},
  name: "register-desktop",
  created() {
    this.$store.commit("authenticate/resetStatus");
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      formatedDob: "",
      errorMessage: "is required",
      page: "MAIN",
      lowerCase: "At least one lowercase",
      upperCase: "At least one uppercase",
      numeric: "At least one numeric",
      characters: "Minimum 8 characters",
      isPasswordValid: true,
      message: "",
      hasAcceptedTerms: false,
      picture: CameraIcon,
      registration: {
        firstName: "",
        surname: "",
        email: "",
        cellNumber: "",
        dob: "",
        id: "",
        Membership_No: "",
        username: "",
        password: "",
        repeatPassword: "",
        userImage: null
      },
      isSizeExceeded: false,
      isSubmit: false,
      regions: []
    };
  },

  validations: {
    hasAcceptedTerms: {
      checked: value => value === true
    },
    registration: {
      firstName: {
        required,
        $lazy: true
      },
      surname: {
        required,
        $lazy: true
      },
      email: {
        required,
        email,
        $lazy: true
      },
      cellNumber: {
        required,
        $lazy: true
      },
      dob: {
        required,
        $lazy: true
      },
      Membership_No: {
        $lazy: true
      },
      id: {
        required,
        $lazy: true
      },
      username: {
        required,
        $lazy: true
      },
      password: {
        required,
        minLength: minLength(8),
        $lazy: true
      },
      repeatPassword: {
        required,
        $lazy: true
      },
      userImage: {
        $lazy: true
      }
    }
  },
  computed: {
    ...mapState("authenticate", ["registrationStatus", "requestResponse"]),
    ...mapGetters("authenticate", {
      isAuthenticated: "isAuthenticated"
    }),
        itemCount() {
      return 6; // Replace with the desired number of club-search-item components
    }
  },

  methods: {
    onGoBack() {
      this.page = this.constants.REGISTRATION.MAIN;
    },
    togglePolicy() {
      this.page = this.constants.REGISTRATION.POLICY;
    },
  }
};
</script>

<style lang="scss" scoped>

.register-card{
margin-top: 9rem;
background: #093248;
border-radius: 20px;
padding: 2rem;
margin: 2rem;
}

@media only screen and (max-width: 600px) {
   .register-card {
      width: 100%;
   }

  //  body{
  //    background-image:url('https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/220px-Image_created_with_a_mobile_phone.png')
  //  }
}

.link-c span {
  color: #3e4547;
  font-weight: bold;
}

.avatar-botton-spacing {
  padding-top: 30px;
}

.text-style {
  font-family: Roboto;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #ffffff;
}

.image-c {
  opacity: 0;
}
.error-message {
  text-align: left;
}
:deep
  .p-datepicker
  .p-datepicker-header
  .p-datepicker-title
  .p-datepicker-month,
:deep .p-datepicker .p-datepicker-header .p-datepicker-title select {
  margin-right: 0.5rem;
  padding: 10px;
  border: 0;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 9px 10px 1px rgb(0 0 0 / 14%),
    0 3px 14px 2px rgb(0 0 0 / 12%);
}
.error-message.p-pt-5 {
  margin-left: -50px;
}
.p-button-rounded.p-button-raised {
  margin-bottom: 20px;
}
:deep .p-avatar img {
  border-radius: 50px;
}
:deep .p-calendar .p-inputtext {
  margin-left: 30px;
}
.pi.pi-id-card {
  z-index: 1;
}

.required-image-error {
  margin-top: 40px;
}

.input-text-class {
    background: transparent !important;
    color: #ffffff;
    border-bottom: 1px solid #000000 !important;
}
span label{
color: #BDBDBD;
}
.p-input-filled .p-inputtext:enabled:focus{
  background: transparent;
  color: white;
}
.p-input-filled .p-inputtext{
  background: transparent;
}

label {
  margin: .5rem;
}

.field-checkbox {
  margin: .5rem;
}
</style>
