<template>
  <div
    class="p-fluid p-grid p-formgrid p-ai-start vertical-container p-m-0 container"
  >
    <Toast position="top-center toast-width" group="br" class="toast" />
    <div class="p-col-12 p-text-left">
      <div @click="onBack">
        <i class="pi pi-arrow-left p-input-icon-left arrow-color"></i>
      </div>
    </div>
    <div class="p-col-6 p-pl-4">
      <radial-progress-bar
        :diameter="100"
        :completed-steps="2"
        :total-steps="4"
        startColor="#093248"
        stopColor="#093248"
        innerStrokeColor="lightgrey"
      >
        <div class="next">{{ 2 }}of 4</div>
      </radial-progress-bar>
    </div>
    <div class="p-col-16 p-as-center">
      <div class="box">
        <div class="title-event">Update Range</div>
        <div class="next">Next: Add official</div>
      </div>
    </div>
    <div class="p-col-12">
      <div class="box">
        <div class="p-fluid p-grid p-formgrid p-text-left">
          <div class="p-field p-col-8 p-pt-2 h-text">Range details</div>
          <div class="p-field p-col-12 p-pt-2">
            <span class="p-float-label p-float-label">
              <InputText
                id="name"
                type="text"
                v-model="rangeModel.name"
                :class="{
                  'p-invalid': v$.rangeModel.name.required.$invalid
                }"
              />
              <label for="inputtext-left">Range name</label>
            </span>
            <p
              class="error-message"
              v-if="v$.rangeModel.name.required.$invalid"
            >
              * Range name is required
            </p>
          </div>
          <div class="p-field p-col-12 p-pt-3">
            <span class="p-float-label">
              <Dropdown
                v-model="rangeModel.targetID"
                :options="selectedClubTargets"
                optionValue="targetID"
                optionLabel="name"
              />
              <label for="inputtext-left">Target</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-pt-3">
            <div class="p-grid">
              <div class="p-col-6">
                <input
                  type="radio"
                  id="Time based"
                  @click="timeBased()"
                  name="rangeType"
                  value="rangeType"
                  checked
                  v-if="rangeModel.isScoringTimeBased == true"
                />
                <input
                  type="radio"
                  id="Time based"
                  @click="timeBased()"
                  name="rangeType"
                  value="rangeType"
                  v-if="rangeModel.isScoringTimeBased == false"
                />
                <label for="Time based">Time based</label><br />
              </div>
              <div class="p-col-6">
                <input
                  type="radio"
                  id="Score based"
                  @click="scoreBased()"
                  name="rangeType"
                  value="rangeType"
                  checked
                  v-if="rangeModel.isScoringTimeBased == false"
                />
                <input
                  type="radio"
                  id="Score based"
                  @click="scoreBased()"
                  name="rangeType"
                  value="rangeType"
                  v-if="rangeModel.isScoringTimeBased == true"
                />
                <label for="Score based">Score based</label><br />
              </div>
            </div>
          </div>
          <div class="p-field p-col-12 p-pt-3">
            <span class="p-float-label">
              <InputNumber
                id="maxScore"
                v-model="rangeModel.targetScore"
                :class="{
                  'p-invalid': v$.rangeModel.targetScore.required.$invalid
                }"
              />
              <label for="inputtext-left">Target max score</label>
            </span>
            <p
              class="error-message"
              v-if="v$.rangeModel.targetScore.required.$invalid"
            >
              * Target max score required
            </p>
          </div>
          <div class="p-field p-col-12 p-pt-3">
            <span class="p-float-label">
              <InputNumber
                id="rounds"
                v-model="rangeModel.roundCount"
                :class="{
                  'p-invalid': v$.rangeModel.roundCount.required.$invalid
                }"
              />
              <label for="inputtext-left">Round count</label>
            </span>
            <p
              class="error-message"
              v-if="v$.rangeModel.roundCount.required.$invalid"
            >
              * Round count requires
            </p>
          </div>
          <div class="p-field p-col-12 p-pt-3">
            <Textarea
              placeholder="Range description"
              v-model="rangeModel.rangeDescription"
              :autoResize="true"
              rows="5"
              cols="30"
              :class="{
                'p-invalid': v$.rangeModel.rangeDescription.required.$invalid
              }"
            />
          </div>
          <p
            class="error-message"
            v-if="v$.rangeModel.rangeDescription.required.$invalid"
          >
            * Range description is required
          </p>
        </div>
      </div>
    </div>
    <div class="p-field p-col-12 p-pt-4">
      <Button
        label="UPDATE"
        class="p-button-raised p-button-rounded p-px-2 p-m-0"
        @click="onSaveRange"
      />
    </div>
    <div class="p-field p-col-12 p-pt-4">
      <Button
        label="REMOVE"
        class="p-button-text p-px-2 p-m-0"
        @click="removeRange"
      />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import RadialProgressBar from "vue-radial-progress";
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
export default {
  name: "UpdateRangeItem",
  components: {
    RadialProgressBar
  },
  setup() {
    return { v$: useVuelidate() };
  },
  created() {
    for (var i in this.rangeInformation) {
      (this.rangeModel.rangeID = this.rangeInformation[i].rangeID),
        (this.rangeModel.name = this.rangeInformation[i].name),
        (this.rangeModel.targetScore = this.rangeInformation[i].targetScore),
        (this.rangeModel.targetID = this.rangeInformation[i].targetID),
        (this.rangeModel.targetName = this.rangeInformation[i].targetName),
        (this.rangeModel.isScoringTimeBased = this.rangeInformation[
          i
        ].isScoringTimeBased),
        (this.scoreType = this.rangeInformation[i].isScoringTimeBased),
        (this.rangeModel.roundCount = this.rangeInformation[i].roundCount),
        (this.rangeModel.rangeDescription = this.rangeInformation[
          i
        ].rangeDescription);
    }
  },
  data() {
    return {
      scoreType: false,
      rangeModel: {
        rangeID: "",
        name: "",
        targetScore: 0,
        targetID: "",
        targetName: "",
        isScoringTimeBased: false,
        roundCount: 0,
        rangeDescription: ""
      }
    };
  },
  validations: {
    rangeModel: {
      name: {
        required,
        $lazy: true
      },
      targetScore: {
        required,
        $lazy: true
      },
      roundCount: {
        required,
        $lazy: true
      },
      rangeDescription: {
        required,
        $lazy: true
      }
    }
  },
  computed: {
    ...mapState("targetModule", ["selectedClubTargets"]),
    ...mapState("eventModule", [
      "status",
      "matchID",
      "selectedMatch",
      "rangeInformation",
      "selectedRange"
    ])
  },

  methods: {
    onBack() {
      this.$router.push({
        name: "update-event"
      });
    },
    timeBased() {
      this.scoreType = true;
    },
    scoreBased() {
      this.scoreType = false;
    },
    onSaveRange() {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.rangeModel.isScoringTimeBased = this.scoreType;
        this.$store
          .dispatch("eventModule/updateRangeDetails", this.rangeModel)
          .then(() => {
            this.$store.dispatch("eventModule/match", this.matchID).then(() => {
              this.$toast.removeAllGroups();
              this.$toast.add({
                severity: "success",
                summary: "Range successfully updated",
                group: "br",
                life: 1000
              });
            });
          });
      }
    },

    removeRange() {
      this.$store
        .dispatch("eventModule/deleteRange", this.selectedRange)
        .then(() => {
          this.$store.dispatch("eventModule/match", this.matchID).then(() => {
            this.$toast.removeAllGroups();
            this.$toast.add({
              severity: "success",
              summary: "Range removed successfully",
              group: "br",
              life: 1000
            });
          });
        });
    }
  }
};
</script>
<style lang="scss" scoped>
.title-event {
  font-size: 23px;
  font-style: normal;
  font-weight: 700;

  letter-spacing: 1.25px;
  text-align: right;
}

.next {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;

  letter-spacing: 1.25px;
  text-align: right;
}

.toast-width {
  width: 320px;
}

.arrow-color {
  color: rgba(0, 0, 0, 0.6);
}
</style>
