import { Service } from "./service";

class UtilService extends Service {
  constructor() {
    super();
  }

  async getProvinces(cb, errorCb) {
    await this.https
      .get("province/Provinces")
      .then((res) => {
        cb(res.data);
      })
      .catch((error) => {
        errorCb(error);
      });
  }

  async getFaqs(cb, errorCb) {
    await this.https
      .get("faq/GetFaqs")
      .then((res) => {
        cb(res.data);
      })
      .catch((error) => {
        errorCb(error);
      });
  }
}

export default new UtilService();
