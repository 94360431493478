<template>
   <Toast position="top-center toast-width" group="br" class="toast"/>
   <Dialog class="popup" :showHeader=null v-model:visible="selectedAdmin" :modal="true">
      <div class="container">
         <img class="popup-image" v-bind:src="''+adminInfo.image" alt="">
         <div class="bottom-left">{{adminInfo.firstName + " " + adminInfo.surname}}</div>
      </div>
      <div class="email-div">
         <label>{{adminInfo.email}}</label>
      </div>
      <hr/>
      <template #footer>
         <Button label="CLOSE" @click="closePopup" class="p-button-text"/>
         <Button label="REMOVE" @click="openConfirmation" class="p-button-text"/>
      </template>
   </Dialog>
   <Dialog class="popup" :showHeader=null v-model:visible="confirmationPopupVisibility">
      <div class="email-div">
         <label class="confirmation-label">Are you sure you want to remove <span class="confirmation-label-name">{{adminInfo.firstName + " " + adminInfo.surname}}</span>?</label>
      </div>
      <template #footer>
         <Button label="CANCEL" @click="closeConfirmationPopup" class="p-button-text"/>
         <Button label="REMOVE" @click="removeAdmin" class="p-button-text"/>
      </template>
   </Dialog>
   <div class="p-grid background">
      <div class="p-col-12">
         <div class="p-grid heading">
            <div class="p-col-2">
               <router-link to="/club">
                  <i class="pi pi-arrow-left"></i>
               </router-link>
            </div>
            <div class="p-col-8">
               <HeaderLogo/>
               <h4>Club Admins</h4>
            </div>
            <div class="p-col-2">
               <router-link to="/admin/add" v-if="is_admin">
                  <i class="pi pi-plus"></i>
               </router-link>
            </div>
         </div>
      </div>
      <div class="p-col-12 p-pb-4 search">
         <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText
               type="text"
               v-model="search"
               placeholder="Search by name/email address "
               />
         </span>
      </div>
      <div class="p-col-12 p-px-2 p-py-4 content-area">
        <div class="p-col-12" v-if="adminsFiltered.length === 0">No admins found</div>
         <div class="p-col-12" v-for="b in adminsFiltered" :key="b.id">
            <admin-item :admins="b" />
         </div>
      </div>
   </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import AdminItem from "./AdminItem.vue";
import HeaderLogo from "../../shared/HeaderLogo.vue"
export default {
  name: "Admins",
  components: { AdminItem, HeaderLogo },
  created() {
    this.$store.dispatch(
      "clubModule/clubAdminUsers",
      this.selectedClub.clubID
    );
  },
    computed: {
    ...mapGetters({
      clubBranchImages: "branchModule/getClubBranchesImages",
      clubAdminImages: "clubModule/getClubAdminImages",
    }),
    ...mapState("clubModule", ["approvedClubs", 
    "selectedClub", 
    "selectedAdmin",
    "selectedAdminId",
    "adminInfo",
    "confirmationPopupVisibility", 
    "selectedClubAdmins"]),
    
    ...mapState("eventModule", [
      "getMatchesByClubStatus",
      "selectedClubMatches",
    ]),
    ...mapState("authenticate", ["user", "is_admin"]),

    adminsFiltered() {
      return this.selectedClubAdmins.filter(
        (a) =>
          this.search === "" ||
          a.firstName.toLowerCase().includes(this.search.toLowerCase()) || a.email.toLowerCase().includes(this.search.toLowerCase()) 
      );
    },
  },
  data() {
    return { 
      search: "",
      removeAdminModel: {
        UserId: "",
        ClubId: "",
      },
       };
  },
  methods: {
    closePopup(){
      this.$store.commit("clubModule/updateSelectedAdmin", false);
    },

    openConfirmation(){
      this.$store.commit("clubModule/updateSelectedAdmin", false);
      this.$store.commit("clubModule/updateConfirmationPopupVisibility", true);
    },

    closeConfirmationPopup(){
      this.$store.commit("clubModule/updateConfirmationPopupVisibility", false);
    },
    
    removeAdmin(){
      this.removeAdminModel.UserId = this.selectedAdminId;
      this.removeAdminModel.ClubId = this.selectedClub.clubID;
      this.$store.dispatch("clubModule/removeClubAdmin", this.removeAdminModel).then(() => {
        this.$store.dispatch(
          "clubModule/clubAdminUsers",
          this.selectedClub.clubID
        );
        this.$store.commit("clubModule/updateConfirmationPopupVisibility", false);
        this.$toast.add({severity: 'success', summary: "User removed successfully", group: 'br', life: 1000});
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  position: relative;
  color: white;
}

.bottom-left {
  position: absolute;
  bottom: 8px;
  left: 17px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 24px;
}

.email-div{
  padding-left:17px;
  padding-top:16px;
  padding-bottom: 16px;
}

.confirmation-label{
  font-family: Roboto; 
  font-style: normal; 
  font-weight: normal; 
  font-size: 20px; 
  line-height: 25px;
}

.popup{
  width:329px;
}

.confirmation-label-name{
  font-weight: bold;
}

.toast-width{
  width: 320px
}

.popup-image{
  padding-left:0px; 
  width:329px; 
  height: 176px
}
</style>