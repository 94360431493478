<template>
  <div class="p-grid background">
    <div class="p-col-12">
      <div class="p-grid heading">
        <div class="p-col-2">
          <router-link to="/club">
            <i class="pi pi-arrow-left"></i>
          </router-link>
        </div>
        <div class="p-col-8">
          <HeaderLogo />
          <h4>Match category</h4>
        </div>
        <div class="p-col-2">
          <router-link to="/category/create" v-if="is_admin">
            <i class="pi pi-plus"></i>
          </router-link>
        </div>
      </div>
    </div>

    <div class="p-col-12 p-pb-4 search">
      <span class="p-input-icon-left">
        <i class="pi pi-search" />
        <InputText type="text" v-model="search" placeholder="Search by name " />
      </span>
    </div>

    <div class="p-col-12 p-p-4 content-area">
      <div v-if="categoriesFiltered.length == 0">No divisions found</div>
      <div
        class="p-col-12"
        v-for="c in categoriesFiltered"
        :key="c.categoryID"
      >
        <div class="p-grid item h-70-custom p-pl-1">
          <div class="p-col-9 p-as-center p-text-left">
            <div class="vertical-container">
              <div class="p-col-12 title-mini">{{ c.name }}</div>
            </div>
          </div>
          <div class="p-col-3 p-as-center p-text-left" @click="onEdit(c)">
            <div class="vertical-container">
              <div class="p-col-12 action">EDIT</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import HeaderLogo from "../../shared/HeaderLogo.vue"
export default {
  name: "categories",
  components: { HeaderLogo },
  created() {
    this.$store.dispatch(
      "categoryModule/categoriesForClub",
      this.selectedClub.clubID
    );
  },
  data() {
    return { search: "" };
  },
  computed: {
    ...mapState("clubModule", ["selectedClub"]),
    ...mapState("categoryModule", ["selectedClubCategories"]),
    ...mapState("authenticate", ["user", "is_admin"]),

    categoriesFiltered() {
      return this.selectedClubCategories.filter(
        (a) =>
          this.search === "" ||
          a.name.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },
  methods: {
    onEdit(category) {
      this.$store.commit("categoryModule/updateSelectedCategory", category);
      this.$router.push({ name: "update-category" });
    },
  },
};
</script>

<style lang="scss" scoped>
:deep .search {
  .p-input-filled .p-inputtext {
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
  }
  .p-inputtext {
    border-radius: 30px;
  }
}

.title-mini {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #0a0a0a;
}
</style>