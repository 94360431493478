<template>
  <div>
    <div
      class="p-grid centered-container h-70 container"
      v-if="resetPasswordStatus === 'success'"
    >
      <div><img :src="successImage" /></div>
      <div class="p-col-12 success-text">
        You have successfully updated your password
      </div>
      <div class="p-col-12">
        <Button
          class="p-button-rounded p-button-raised"
          type="button"
          label="CONTINUE TO LOG IN"
          @click="onContinue"
        />
      </div>
    </div>
    <div
      class="p-grid centered-container h-70 container"
      v-if="resetPasswordStatus !== 'success'"
    >
      <div class="p-col-12">
        <div class="p-grid heading">
          <div class="p-col-1">
            <router-link to="/home">
              <i class="pi pi-arrow-left"></i>
            </router-link>
          </div>
          <div class="p-col-11 p-pt-6">
            <HeaderLogo />
          </div>
        </div>
      </div>
      <div class="p-col-12">
        <Message
          severity="error"
          v-if="resetPasswordStatus === 'error'"
          :life="3000"
          :sticky="false"
          >{{
            resetPasswordResponse === ""
              ? "An error occured"
              : resetPasswordResponse
          }}</Message
        >
      </div>

      <div class="p-col-12">
        <div class="box box-margin">
          <div class="p-fluid p-grid p-formgrid p-text-left">
            <div class="p-col-12">
              <ProgressBar
                v-if="resetPasswordStatus === 'loading'"
                mode="indeterminate"
                class="progress"
              />
            </div>
            <div class="p-field p-col-12">
              <span class="p-input-icon-left">
                <i class="pi pi-lock lock-icon" />
                <Password
                  toggleMask
                  placeholder="Password"
                  v-model="newPassword.password"
                  :class="{
                    'p-invalid':
                      v$.newPassword.password.required.$invalid ||
                      v$.newPassword.password.minLength.$invalid
                  }"
                />
              </span>
              <p
                class="error-message"
                v-if="
                  v$.newPassword.password.required.$invalid ||
                    v$.newPassword.password.minLength.$invalid
                "
              >
                Please enter valid password
              </p>
            </div>

            <div class="p-field p-col-12 button-top-margin">
              <span class="p-input-icon-left">
                <i class="pi pi-lock lock-icon" />
                <span>
                  <Password
                    v-model="newPassword.confirmPassword"
                    toggleMask
                    placeholder="Confirm Password"
                    :feedback="false"
                    :class="{
                      'p-invalid':
                        v$.newPassword.confirmPassword.required.$invalid ||
                        v$.newPassword.confirmPassword.minLength.$invalid
                    }"
                  />
                </span>
              </span>
              <p
                class="error-message"
                v-if="
                  newPassword.password !== newPassword.confirmPassword &&
                    newPassword.confirmPassword.length !== 0
                "
              >
                Confirm password must match password
              </p>
            </div>
            <div class="p-col-12">
              <Button
                type="button"
                class="p-button-rounded p-button-raised"
                :class="[
                  isDisabled ? disabled - create - button : create - button
                ]"
                v-bind:disabled="!isDisabled"
                @click="onUpdatePassword"
                label="CREATE PASSWORD"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";
import { mapState } from "vuex";
import successImage from "../../assets/successImage.svg";
import HeaderLogo from "../shared/HeaderLogo.vue";

export default {
  name: "createNewPassword",
  components: { HeaderLogo },
  setup() {
    return { v$: useVuelidate() };
  },
  created() {
    this.$store.commit("authenticate/resetStatus");
  },
  data() {
    return {
      passwordsMatch: null,
      newPassword: {
        password: "",
        confirmPassword: ""
      },
      successImage: successImage
    };
  },
  validations: {
    newPassword: {
      password: {
        required,
        minLength: minLength(8),
        $lazy: true
      },
      confirmPassword: {
        required,
        minLength: minLength(8),
        $lazy: true
      }
    }
  },
  computed: {
    ...mapState("authenticate", [
      "resetPasswordStatus",
      "resetPasswordResponse"
    ]),
    isDisabled() {
      var disabled =
        this.newPassword.confirmPassword.length > 0 &&
        this.newPassword.password.length > 0;
      return disabled;
    }
  },
  methods: {
    onContinue() {
      this.$router.push({ name: "login" });
    },
    async onUpdatePassword() {
      this.v$.$touch();
      if (this.newPassword.password === this.newPassword.confirmPassword)
        this.passwordsMatch = true;

      if (!this.v$.$invalid && this.passwordsMatch) {
        await this.$store.dispatch("authenticate/resetPassword", {
          email: this.$route.query.email,
          password: this.newPassword.password,
          token: this.$route.query.token.replaceAll(" ", "+")
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.heading {
  font-size: 20px;
  font-weight: bold;
  align-items: center;
  text-align: center;
  letter-spacing: 1.25px;
  color: #3e4547;
  .pi {
    color: #000000;
  }
}

.h-70 {
  height: 70vh;
}

.progress {
  height: 0.09em;
  width: 98%;
  margin-left: 2px;
}

.box-margin {
  margin-bottom: 350px;
}

.disabled-create-button {
  background: #d8d8d8;
  height: 46.99px;
  color: #bdbdbd;
}

.create-button {
  height: 46.99px;
}

.lock-icon {
  z-index: 1;
}

::v-deep .p-inputtext {
  padding-left: 45px;
}

.success-text {
  font-family: Roboto;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 1.25px;
  color: #093248;
}

.button-top-margin {
  margin-bottom: 80px;
}

.container {
  overflow: hidden;
}
</style>
