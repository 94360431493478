<template>
  <div>
    <div class="p-grid item h-120-custom" @click="onExpand">
      <div class="p-col-4 p-as-center">
        <Avatar :image="match.clubImage" class="p-mr-2" size="xlarge" />
      </div>
      <div class="p-col-8 p-as-center p-text-left">
        <div class="vertical-container">
          <div class="title-mini heading-styling">Match: {{ match.name }}</div>
          <div class="sub-value-mini heading-styling">Club: {{ match.clubName }}</div>
          <div class="sub-value-mini" v-if="!isExpanded">
            {{
            match.spotsLeft > 0
            ? `Spots left: ${match.spotsLeft}`
            : `fully booked`
            }}
          </div>
          <div
            class="sub-value-mini"
          >Match date: {{ new Date(match.startDate).toLocaleDateString() }}</div>
          <div
            class="sub-value-mini"
          >Status: {{ match.isUserRegistered == true ? `Registered` : `Not registered` }}</div>
        </div>
      </div>
    </div>

    <div class="p-grid item pointer" v-if="isExpanded">
      <div class="p-col-12 p-p-0">
        <div class="p-d-flex rows p-pl-2">
          <div class="p-col-5 p-text-left sub-t">Club:</div>
          <div class="p-col-7 p-text-right sub-value-mini">{{ match.clubName }}</div>
        </div>

        <div class="p-d-flex rows p-pl-2" v-if="match.branchName != null">
          <div class="p-col-5 p-text-left sub-t">Club branch:</div>
          <div class="p-col-7 p-text-right sub-value-mini">{{ match.branchName }}</div>
        </div>

        <div class="p-d-flex rows p-pl-2">
          <div class="p-col-5 p-text-left sub-t">Match date:</div>
          <div
            class="p-col-7 p-text-right sub-value-mini"
          >{{ new Date(match.startDate).toDateString() }} {{ new Date(match.startDate).toLocaleTimeString() }}</div>
        </div>

        <div class="p-d-flex rows p-pl-2">
          <div class="p-col-5 p-text-left sub-t">Registration opens:</div>
          <div
            class="p-col-7 p-text-right sub-value-mini"
          >{{ new Date(match.registrationStartDate).toDateString() }} {{ new Date(match.registrationStartDate).toLocaleTimeString() }}</div>
        </div>

        <div class="p-d-flex rows p-pl-2">
          <div class="p-col-6 p-text-left sub-t">Match type:</div>
          <div class="p-col-6 p-text-right sub-value-mini">{{ match.type }}</div>
        </div>

        <div class="p-d-flex rows p-pl-2">
          <div class="p-col-6 p-text-left sub-t">Match director:</div>
          <div class="p-col-6 p-text-right sub-value-mini">{{ match.matchDirector }}</div>
        </div>

        <div class="p-d-flex rows p-pl-2">
          <div class="p-col-6 p-text-left sub-t">Participants:</div>
          <div class="p-col-6 p-text-right sub-value-mini">
            {{
            match.spotsLeft > 0
            ? `${match.spotsLeft} spots left`
            : `fully booked`
            }}
          </div>
        </div>

        <div class="p-d-flex rows p-pl-2">
          <div class="p-col-6 p-text-left sub-t">Status:</div>
          <div class="p-col-6 p-text-right sub-value-mini">
            {{
            !match.isUserRegistered ? 'Not registered' : 'Registered'
            }}
          </div>
        </div>

        <div class="p-d-flex rows p-pl-2 action">
          <div
            class="p-col-4 p-text-right"
            v-if="!match.isUserRegistered &&
              today >= new Date(match.registrationStartDate) &&
              today <= new Date(match.registrationEndDate)"
            @click="onGoToMatch"
          >REGISTER</div>
          <div
            @click="onViewRanges"
            class="-text-right"
            :class="{
              'p-col-4 p-text-right': !match.isUserRegistered,
              'p-col-8': match.isUserRegistered,
            }"
          >RANGES</div>
          <div
            class="-text-right"
            :class="{
              'p-col-4 p-text-right': !match.isUserRegistered,
              'p-col-4': match.isUserRegistered,
            }"
            @click="onExpand"
          >CLOSE</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    match: {}
  },
  data() {
    return {
      isExpanded: false,
      today: new Date()
    };
  },
  methods: {
    onExpand() {
      this.isExpanded = !this.isExpanded;
    },
    onGoToMatch() {
      this.$store.commit("eventModule/updateSelectedMatch", this.match);

      this.$router.push({ name: "match" });
    },
    onViewRanges() {
      this.$store
        .dispatch("captureScoreModule/rangesForMarch", this.match.matchID)
        .then(() => {
          this.$store.commit(
            "captureScoreModule/updateSelectedMatch",
            this.match
          );
          this.$router.push({ name: "view-ranges" });
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.title-mini {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #0a0a0a;
}

.sub-t {
  font-weight: bold;
  font-size: 12px;
  text-align: right;
  letter-spacing: 0.25px;
  color: #000000;
}

.sub-value-mini {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #979797;
  letter-spacing: 0.25px;
}

.rows {
  background: #ffffff;
  border-width: 0px 1px 1px 1px;
  border-color: #e1e1e1;
  border-style: solid;
  box-sizing: border-box;
}

.head {
  height: 64px;
}

.heading-styling {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>