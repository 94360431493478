<template>
  <div>
    <confirmation
      v-if="updateClubStatus === 'success'"
      :title="'You have successfully updated your club'"
      :status="updateClubStatus"
      :fallback="'/clubs'"
    />
    <div class="p-grid background" v-if="updateClubStatus !== 'success'">
      <div class="p-col-12">
        <div class="p-grid heading">
          <div class="p-col-2">
            <router-link to="/club">
              <i class="pi pi-arrow-left"></i>
            </router-link>
          </div>
          <div class="p-col-8" v-if="is_admin">Update club</div>
          <div class="p-col-8" v-if="!is_admin">Club details</div>

          <div class="p-col-2" />
        </div>
      </div>

      <div class="p-col-12 h-50-custom">
        <div class="open-file">
          <input
            ref="image"
            type="file"
            accept="image/*"
            @change="uploadImage($event)"
            class="pi pi-arrow-left"
            id="upload-photo"
            :disabled="!is_admin"
          />
        </div>
        <div class="picture-holder">
          <label for="upload-photo">
          <img
            class="p-mr-2 bg-grey-custom clubLogo"
            size="xlarge"
            v-bind:src="clubModel.image"
          />
          </label>
        </div>
      </div>

      <div class="p-col-12 p-pt-4 content-area">
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-col-12">
            <Message
              severity="error"
              v-if="updateClubStatus === 'error'"
              :life="3000"
              :sticky="false"
              >Club update. Please try again</Message
            >
          </div>
          <div
            class="p-col-12 error-message required-image-error"
            v-if="clubModel.picture == '' && clubModel.UserImageUrl == ''"
          >
            {{ constants.IMAGE_VALIDATION.IMAGE_REQUIRED }}
          </div>
          <div class="p-col-12 error-message p-pt-4" v-if="isSizeExceeded">
            {{ constants.IMAGE_VALIDATION.EXCEEDED_IMAGE_SIZE_ERROR }}
          </div>
          <div class="p-col-12 p-text-left p-pt-4 p-pb-3">Club details</div>

          <div class="p-field p-col-12">
            <span class="p-float-label p-input-icon-left">
              <i class="pi pi-globe" />
              <InputText
                type="text"
                v-model="clubModel.clubName"
                :class="{
                  'p-invalid': v$.clubModel.clubName.required.$invalid
                }"
                :disabled="!is_admin"
              />
              <label for="inputtext-left ">Club name</label>
            </span>
            <p
              class="error-message"
              v-if="v$.clubModel.clubName.required.$invalid"
            >
              * Club name is required
            </p>
          </div>

          <div class="p-field p-col-12">
            <span class="p-float-label p-input-icon-left">
              <i class="pi pi-envelope" />
              <InputText
                id="email"
                type="email"
                v-model="clubModel.email"
                :class="{ 'p-invalid': v$.clubModel.email.required.$invalid }"
                :disabled="!is_admin"
              />
              <label for="inputtext-left ">Email address</label>
            </span>
            <p
              class="error-message"
              v-if="v$.clubModel.email.required.$invalid"
            >
              {{ constants.FORM_VALIDATION_ERRORS.EMAIL_REQUIRED }}
            </p>
            <p class="error-message" v-if="v$.clubModel.email.email.$invalid">
              {{ constants.FORM_VALIDATION_ERRORS.EMAIL_INVALID }}
            </p>
          </div>

          <div class="p-field p-col-12">
            <span class="p-float-label p-input-icon-left">
              <i class="pi pi-map-marker" />
              <InputText
                id="clubAddress"
                type="text"
                v-model="clubModel.clubAddress"
                :class="{
                  'p-invalid': v$.clubModel.clubAddress.required.$invalid
                }"
                :disabled="!is_admin"
              />
              <label for="inputtext-left ">Location</label>
            </span>
            <p
              class="error-message"
              v-if="v$.clubModel.clubAddress.required.$invalid"
            >
              {{ constants.FORM_VALIDATION_ERRORS.LOCATION_REQUIRED }}
            </p>
          </div>
          <div class="p-field p-col-12 p-text-left" v-if="is_admin">
            <span class="p-float-label p-input-icon-left">
              <i class="pi pi-search index-1" />
              <Dropdown
                v-model="clubModel.provinceID"
                :options="provinces"
                class="p-pl-4"
                optionLabel="name"
                optionValue="value"
                :class="{
                  'p-invalid': v$.clubModel.provinceID.required.$invalid
                }"
                :disabled="!is_admin"
              />
              <label for="inputtext-left ">Province</label>
            </span>
          </div>

          <div class="p-field p-col-12" v-if="is_admin">
            <span class="p-float-label p-input-icon-left">
              <i class="pi pi-map" />
              <InputText
                id="googleMap"
                type="text"
                v-model="clubModel.googleMaps"
                :class="{
                  'p-invalid': v$.clubModel.googleMaps.required.$invalid
                }"
                :disabled="!is_admin"
              />
              <label for="inputtext-left ">Google maps link</label>
            </span>
            <p
              class="error-message"
              v-if="v$.clubModel.googleMaps.required.$invalid"
            >
              {{ constants.FORM_VALIDATION_ERRORS.GOOGLE_LINK_REQUIRED }}
            </p>
          </div>

          <div class="p-col-12 p-text-left p-pt-4 p-pb-3">Club admin</div>

          <div class="p-col-12 p-field">
            <span class="p-float-label p-input-icon-left">
              <i class="pi pi-user" />

              <InputText type="text" v-model="clubModel.adminName" disabled />
              <label for="inputtext-left ">Admin name</label>
            </span>
          </div>
          <div class="p-field p-col-12">
            <span class="p-float-label p-input-icon-left">
              <i class="pi pi-envelope" />

              <InputText type="text" v-model="clubModel.adminEmail" disabled />
              <label for="inputtext-left ">Email address</label>
            </span>
          </div>
          <div class="p-col-12">
            <div
              class="p-col-12 error-message required-image-error"
              v-if="clubModel.picture == '' && clubModel.UserImageUrl == ''"
            >
              {{ constants.IMAGE_VALIDATION.IMAGE_REQUIRED }}
            </div>
            <div class="p-col-12 error-message" v-if="isSizeExceeded">
              {{ constants.IMAGE_VALIDATION.EXCEEDED_IMAGE_SIZE_ERROR }}
            </div>
            <p
              class="error-message"
              v-if="v$.clubModel.clubName.required.$invalid"
            >
              * Club name is required
            </p>
            <p
              class="error-message"
              v-if="v$.clubModel.email.required.$invalid"
            >
              {{ constants.FORM_VALIDATION_ERRORS.EMAIL_REQUIRED }}
            </p>
            <p class="error-message" v-if="v$.clubModel.email.email.$invalid">
              {{ constants.FORM_VALIDATION_ERRORS.EMAIL_INVALID }}
            </p>
            <p
              class="error-message"
              v-if="v$.clubModel.clubAddress.required.$invalid"
            >
              {{ constants.FORM_VALIDATION_ERRORS.LOCATION_REQUIRED }}
            </p>
          </div>
          <div class="p-field p-col-12 p-pt-4" v-if="is_admin">
            <Button
              class="p-button-rounded p-button-raised"
              type="button"
              @click="createClub()"
              label="Update"
              ><ProgressSpinner
                v-if="updateClubStatus === 'loading'"
                class="progress-spinner-custom"
                strokeWidth="5"
                animationDuration=".5s"
              />
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, email, maxLength } from "@vuelidate/validators";
import { mapState } from "vuex";
import Confirmation from "../manage-clubs/Confirmation";

export default {
  components: {
    Confirmation
  },
  name: "createClub",
  setup() {
    return { v$: useVuelidate() };
  },
  created() {
    this.$store.commit("clubModule/resetStatus");
    this.$store.dispatch("utilModule/provinces");
    this.clubModel = this.selectedClub;
  },
  data() {
    return {
      selectedAdmin: { email: "", userName: "", id: "" },
      filteredList: null,
      pic: "",
      clubModel: {
        clubName: "",
        clubAddress: "",
        provinceID: 0,
        Website: "",
        email: "",
        Telephone: "",
        googleMaps: "",
        userPhoto: "",
        userImageUrl: "",
        Description: "",
        UserID: "",
        picture: ""
      },
      isSizeExceeded: false
    };
  },
  validations: {
    clubModel: {
      clubName: {
        required,
        $lazy: true
      },
      clubAddress: {
        required,
        maxLength: maxLength(120),
        $lazy: true
      },
      provinceID: {
        required,
        $lazy: true
      },

      email: {
        required,
        email,
        $lazy: true
      },

      googleMaps: {
        required,
        $lazy: true
      }
    }
  },
  computed: {
    ...mapState("clubModule", ["updateClubStatus", "selectedClub"]),
    ...mapState("utilModule", ["status", "provinces"]),
    ...mapState("authenticate", ["user", "is_admin"])
  },

  methods: {
    async getUsers(query) {
      await this.$store.dispatch("authenticate/userSearch", query);
      this.query = query;
    },
    async createClub() {
      this.v$.$touch();
      if (!this.v$.$invalid && this.isDifferent()) {
        this.clubModel.UserID = this.user.id;
        let form = new FormData();
        form.append("clubName", this.clubModel.clubName);
        form.append("userID", this.user.id);
        form.append("clubID", this.selectedClub.clubID);
        form.append("email", this.clubModel.email);
        form.append("clubAddress", this.clubModel.clubAddress);
        form.append("provinceID", this.clubModel.provinceID);
        form.append("googleMaps", this.clubModel.googleMaps);
        form.append("UserPhoto", this.clubModel.picture);

        await this.$store.dispatch("clubModule/update", form);
      }
      this.isSizeExceeded = false;
    },
    isDifferent() {
      // TODO check if at least one field changed
      return true;
    },

    uploadImage(event) {
      this.isSizeExceeded = false;
      this.clubModel.picture = event.target.files[0];
      const input = this.$refs.image;
      const files = input.files;
      this.fileSize = files[0].size;
      if (this.fileSize >= this.constants.IMAGE_VALIDATION.IMAGE_MAX_SIZE) {
        this.isSizeExceeded = true;
        this.clubModel.picture = "";
      }
      if (
        files &&
        files[0] &&
        this.fileSize < this.constants.IMAGE_VALIDATION.IMAGE_MAX_SIZE
      ) {
        const reader = new FileReader();
        reader.onload = e => {
          this.clubModel.image = e.target.result;
        };
        reader.readAsDataURL(files[0]);
        this.$emit("input", files[0]);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.clubLogo {
  width: 85px;
  height: 80px;
  border-radius: 50%;
}

.image-c {
  opacity: 0;
}

.required-image-error {
  margin-top: 20px;
}
</style>