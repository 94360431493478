<template>
  <div id="footer">
    <div class="container">
      <p>
        quick score pro © All right Reserved.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "footer-desktop",
};
</script>

<style scoped>
#footer {
  margin-top: 6em;
  background-color: #093248;
  height: 167px;
  border-top: 2px solid #000000;
}

#footer .icon {
  height: 23px;
  display: inline-block;
  /* margin-bottom: -6px; */
}

.hr {
  border-bottom: 1px solid #dbdbdb;
  margin: 3em 0;
}

#footer p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 40px;
  align-items: center;
  letter-spacing: 3.25px;
  text-transform: uppercase;

  color: #ffffff;

  text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.35);
}
</style>
