<template>
    <div class="p-col-12"></div>
      <HeaderLogo />
      <h2 class="faq-header app">FAQs</h2>
      <Accordion class="faq-info" style="width:900px">
        <AccordionTab :header="faq.heading" v-for="faq in faqs" :key="faq.faqID" class="faq-info">
          <ScrollPanel class="scroll faq-info">
              <p>{{ faq.description }}</p>
          </ScrollPanel>
        </AccordionTab>
      </Accordion>
</template>

<script>
import { mapState } from "vuex";
import HeaderLogo from "../../shared/HeaderLogo.vue";

export default {
  name: "faq-desktop",
  components: {HeaderLogo},

  created() {
    this.$store.dispatch("utilModule/faqs");
  },

  computed: {
    ...mapState("utilModule", ["faqs", "getFaqsStatus"])
  },
  methods: {
    onBack() {
      this.$router.go(-1);
    }
  }
};
</script>

<style lang="scss" scoped>
.scroll {
  width: 100%;
  height: 150px !important;
}

.faq-info{
  background-color: #093248;
  color:white;
}

.p-accordion{
  background-color: #093248;
  color: white;
  border-color: white;
}

.p-accordion-tab{
  background-color: #093248;
  color: white;
  border-color: white;
}

.p-accordion-content{
  background-color: #093248;
  color: white;
  border-color: white;
}
.p-accordion-header-link{
  background-color: #093248;
  color: white;
  // border-color: white;
}
.faq-header{
font-family: 'Roboto';
font-style: normal;
font-weight: 700;
font-size: 22px;
line-height: 40px;
/* identical to box height, or 182% */
margin-top: -10px;
margin-left: -50px;
display: flex;
align-items: center;
text-align: center;
letter-spacing: 3.25px;

color: #FFFFFF;

text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.35);
}

.p-accordion p {
    line-height: 1.5;
    margin: 0;
    width: 900px;
    max-width: inherit;
    text-align: justify;
}
</style>