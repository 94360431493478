<template>
   <div class="p-grid background">
   <div class="p-col-12">
      <div class="p-grid heading">
         <div class="p-col-2">
            <router-link v-if="goBackPath == 'fromMatches'" to="/viewranges">
               <i class="pi pi-arrow-left"></i>
            </router-link>
            <router-link v-else to="/match/range-score">
               <i class="pi pi-arrow-left"></i>
            </router-link>
         </div>
         <div class="p-col-8 h-title">Range Targets</div>
         <div class="p-col-2" />
         </div>
         <div class="p-col-12 h-sub-title p-pt-0 p-pb-4">
            {{ selectedRangeTarget.targetName }}
         </div>
      </div>
      <div class="p-col-12 p-pt-4 content-area">
         <div class="p-fluid p-formgrid p-grid">
            <div class="p-col-12 p-text-left p-pt-4 p-pb-3">Range details</div>
            <div class="p-field p-col-12">
               <span class="p-float-label p-input-icon-left">
                  <i class="pi pi-globe" />
                  <InputText
                     type="text"
                     v-model="selectedMatch.name"
                     readonly="readonly"
                     />
                  <label for="inputtext-left ">Match Name</label>
               </span>
            </div>
            <div class="p-field p-col-12">
               <span class="p-float-label p-input-icon-left">
                  <i class="pi pi-globe" />
                  <InputText
                     id="targetName"
                     type="text"
                     v-model="selectedRangeTarget.targetName"
                     readonly="readonly"
                     />
                  <label for="inputtext-left ">Target Name</label>
               </span>
            </div>
            <div class="p-field p-col-12">
               <span class="p-float-label p-input-icon-left">
               <i class="pi pi-info-circle" />
               <Textarea
                  readonly="readonly"
                  :modelValue="selectedRangeTarget.rangeDescription"
                  :autoResize="true" 
                  rows="5" 
                  cols="30"
                  />
               <label for="inputtext-left ">Range Description</label>
               </span>
            </div>
            <div class="p-field p-col-12">
               <label>Range Image</label>
               <img class="target-image" :src="selectedRangeTarget.imageUrl" />
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "view-range-targets",
  computed: {
    ...mapState("captureScoreModule", ["selectedMatch"]),
    ...mapState("targetModule", ["selectedRangeTarget", "goBackPath"]),
    ...mapState("authenticate", ["user", "is_admin"]),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.title-mini {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #0a0a0a;
}

.sub-value-mini {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #979797;
}

.target-image{
  width:100%
}
</style>