<template>
   <div class="p-grid background">
      <div class="p-col-12">
         <div class="p-grid heading">
            <div class="p-col-2">
               <router-link to="/dashboard">
                  <i class="pi pi-arrow-left"></i>
               </router-link>
            </div>
            <div class="p-col-8">
                <HeaderLogo />
               <h4>{{is_admin ? constants.COMMON_TEXT.MY_CLUBS : "Find Clubs"}}</h4>
            </div>
            <div class="p-col-2">
               <router-link to="/clubs/clubinformation">
                  <i class="pi pi-plus"></i>
               </router-link>
            </div>
         </div>
         <p class="p-add-club">Click the plus button to add a club</p>
      </div>
      <div class="p-col-12 p-pb-4 search">
         <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText
               type="text"
               v-model.trim="searchClub"
               @keyup="searchClubByName"
               placeholder="Search by club name "
               />
         </span>
      </div>
      <div class="p-col-12 p-p-4 content-area">
         <div class="p-col-12">
            <ProgressBar
               v-if="searchClubStatus === 'loading'"
               mode="indeterminate"
               class="progress"
               />
         </div>
         <div class="p-col-12" v-if="clubList.length === 0 && searchClubStatus !== 'loading'">{{constants.EMPTY_STATE_TEXT.EMPTY_CLUB_LIST}}</div>
         <div
            class="p-col-12"
            v-for="clubData in clubList"
            :key="clubData.ClubID"
            @click="onViewClub(clubData)"
            >
            <club-item :club="clubData"/>
         </div>
         <div v-if="!is_admin && approvedClubList.length > 9 && searchClubStatus !== 'loading'" @click="loadMore">
            <LoadMore/>
         </div>
      </div>
   </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import ClubItem from "./ClubItem";
import LoadMore from "../main/LoadMore"
import HeaderLogo from "../shared/HeaderLogo.vue"
export default {
    name: "Clubs",
    components: {
        ClubItem,
        LoadMore,
        HeaderLogo
    },
    created() {
        this.skip = 0;
        this.clubList = [];
        if (this.is_admin)
            this.$store.dispatch("clubModule/clubsByUser", {
                userId: this.user.id,
                clubName: this.searchClub
            }).then(() => {
                this.userClubs.forEach((x) => {
                    this.clubList.push({
                        clubID: x.clubID,
                        clubName: x.clubName,
                        clubAddress: x.clubAddress,
                        provinceID: x.provinceID,
                        website: x.website,
                        email: x.email,
                        telephone: x.telephone,
                        googleMaps: x.googleMaps,
                        descrition: x.descrition,
                        approved: x.approved,
                        userImageUrl: x.userImageUrl,
                        timeStamp: x.timeStamp,
                        image: x.image,
                        adminName: x.adminName,
                        adminEmail: x.adminEmail,
                    });
                })
            });
        else this.$store.dispatch("clubModule/clubList", {
            skip: this.skip,
            clubName: this.searchClub,
        }).then(() => {
            this.approvedClubList.forEach((x) => {
                this.clubList.push({
                    clubID: x.clubID,
                    clubName: x.clubName,
                    email: x.email,
                    telephone: x.telephone,
                    clubAddress: x.clubAddress,
                    descrition: x.descrition,
                    adminName: x.adminName,
                    adminEmail: x.adminEmail,
                    image: x.image,
                });
            })
        });
    },
    data() {
        return {
            skip: 0,
            clubList: [],
            searchClub: null
        };
    },
    computed: {
        ...mapGetters({
            clubList: "clubModule/getClubList"
        }),
        ...mapState("clubModule", ["status", "userClubs", "approvedClubs", "approvedClubList", "searchClubStatus"]),
        ...mapState("authenticate", ["user", "is_admin"]),
        clubs() {
            return this.is_admin ? this.userClubs : this.approvedClubList;
        },
    },
    methods: {
        onViewClub(club) {
            this.$router.push({
                name: "club"
            });
            this.$store.commit("clubModule/updateSelectedClub", club);
        },
        loadMore() {
            if (this.searchClub) {
                this.skip = this.skip + 10;
                this.$store.dispatch("clubModule/clubList", {
                    skip: this.skip,
                    clubName: this.searchClub
                }).then(() => {
                    this.approvedClubList.forEach((x) => {
                        this.clubList.push({
                            clubID: x.clubID,
                            clubName: x.clubName,
                            email: x.email,
                            telephone: x.telephone,
                            clubAddress: x.clubAddress,
                            descrition: x.descrition,
                            adminName: x.adminName,
                            adminEmail: x.adminEmail,
                            image: x.image,
                        });
                    })
                });
            } else {
                this.skip = this.skip + 10;
                this.$store.dispatch("clubModule/clubList", {
                    skip: this.skip,
                    clubName: null
                }).then(() => {
                    this.approvedClubList.forEach((x) => {
                        this.clubList.push({
                            clubID: x.clubID,
                            clubName: x.clubName,
                            email: x.email,
                            telephone: x.telephone,
                            clubAddress: x.clubAddress,
                            descrition: x.descrition,
                            adminName: x.adminName,
                            adminEmail: x.adminEmail,
                            image: x.image,
                        });
                    })
                });
            }
        },

        searchClubByName() {
            if (this.searchClub) {
                this.skip = 0;
                if (this.is_admin)
                    this.$store.dispatch("clubModule/clubsByUser", {
                        userId: this.user.id,
                        clubName: this.searchClub
                    }).then(() => {
                        this.clubList = [];
                        this.userClubs.forEach((x) => {
                            this.clubList.push({
                                clubID: x.clubID,
                                clubName: x.clubName,
                                clubAddress: x.clubAddress,
                                provinceID: x.provinceID,
                                website: x.website,
                                email: x.email,
                                telephone: x.telephone,
                                googleMaps: x.googleMaps,
                                descrition: x.descrition,
                                approved: x.approved,
                                userImageUrl: x.userImageUrl,
                                timeStamp: x.timeStamp,
                                image: x.image,
                                adminName: x.adminName,
                                adminEmail: x.adminEmail,
                            });
                        })
                    });
                else this.$store.dispatch("clubModule/clubList", {
                    skip: this.skip,
                    clubName: this.searchClub
                }).then(() => {
                    this.clubList = [];
                    this.approvedClubList.forEach((x) => {
                        this.clubList.push({
                            clubID: x.clubID,
                            clubName: x.clubName,
                            email: x.email,
                            telephone: x.telephone,
                            clubAddress: x.clubAddress,
                            descrition: x.descrition,
                            adminName: x.adminName,
                            adminEmail: x.adminEmail,
                            image: x.image,
                        });
                    })
                });
            } else {
                this.skip = 0;
                if (this.is_admin)
                    this.$store.dispatch("clubModule/clubsByUser", {
                        userId: this.user.id,
                        clubName: null
                    }).then(() => {
                        this.clubList = [];
                        this.userClubs.forEach((x) => {
                            this.clubList.push({
                                clubID: x.clubID,
                                clubName: x.clubName,
                                clubAddress: x.clubAddress,
                                provinceID: x.provinceID,
                                website: x.website,
                                email: x.email,
                                telephone: x.telephone,
                                googleMaps: x.googleMaps,
                                descrition: x.descrition,
                                approved: x.approved,
                                userImageUrl: x.userImageUrl,
                                timeStamp: x.timeStamp,
                                image: x.image,
                                adminName: x.adminName,
                                adminEmail: x.adminEmail,
                            });
                        })
                    });
                else this.$store.dispatch("clubModule/clubList", {
                    skip: this.skip,
                    clubName: null
                }).then(() => {
                    this.clubList = [];
                    this.approvedClubList.forEach((x) => {
                        this.clubList.push({
                            clubID: x.clubID,
                            clubName: x.clubName,
                            email: x.email,
                            telephone: x.telephone,
                            clubAddress: x.clubAddress,
                            descrition: x.descrition,
                            adminName: x.adminName,
                            adminEmail: x.adminEmail,
                            image: x.image,
                        });
                    })
                });
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.load-more-button{
  width: 38.11px;
  height: 37.55px;
  background: #8B0E0F;
  border-radius: 1%;
  border-color: white;
}

.p-add-club{
    color: #ffffff;
    font-size: 12px;
}
:deep .search {
  .p-input-filled .p-inputtext {
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
  }
  .p-inputtext {
    border-radius: 30px;
  }
}
:deep .p-avatar img {
  object-fit: cover;
}
</style>