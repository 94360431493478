<template>
  <div class="p-grid item">
    <div class="p-col-7 p-as-center p-text-left">
      <div class="match-name">{{match.name}}</div>
      <div class="club-name">{{match.clubName}}</div>
    </div>
    <div class="p-col-5 p-as-center p-text-right">
      <div class="vertical-container pointer">
        <div class="box">
          <div @click="onViewScores(match)" class="text-primary">VIEW SCORES</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "MatchListItem",
  props: {
    match: {}
  },
  methods: {
    onViewScores(match) {
      this.$store.commit("captureScoreModule/updateSelectedMatch", match);
      this.$store
        .dispatch("captureScoreModule/getMyScores", {
          MatchId: match.matchID,
          UserId: this.user.id
        })
        .then(() => {
          this.$router.push({
            name: "scoreboard",
            params: { scoreBoardType: "myScores" }
          });
        });
    }
  },
  computed: {
    ...mapState("authenticate", ["user"])
  }
};
</script>

<style lang="scss" scoped>
.club-item {
  .p-avatar.p-avatar-xl {
    width: 100px;
    height: 40px;
    font-size: 2rem;
    object-fit: contain;
  }
}
.match-name {
  height: 16px;
  left: 8.89%;
  right: 60.28%;
  top: calc(50% - 16px / 2 - 189px);
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #3e4547;
}
.club-name {
  height: 14px;
  left: 8.89%;
  right: 77.78%;
  top: calc(50% - 14px / 2 - 172px);
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #3e4547;
}
</style>