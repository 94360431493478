<template>
  <div class="p-grid centered-container h-100hv container">
    <div class="p-col-12 p-text-center" v-if="status === 'loading'">
      Submitting...
      <ProgressBar mode="indeterminate" />
    </div>

    <div class="p-col-12 p-text-center" v-if="status === 'success'">
      <div class="p-col-1">
        <div v-if="isShowBackButton" @click="onBackToCreate">
          <i class="pi pi-arrow-left"></i>
        </div>
      </div>
      <img src="../../images/successLogo.png" alt />
      <p>Great</p>
      <p>{{ title }}</p>
      <Button
        class="p-button-rounded p-button-raised"
        type="button"
        label="Continue"
        @click="onContinue"
      />
    </div>
    <div class="p-col-12 p-text-center" v-if="status === 'error'">
      <div class="p-col-12" v-if="errorResponse === '' || errorResponse === undefined">
        <Message severity="error" :sticky="true">An error eccured. Please try again</Message>
      </div>
      <div class="p-col-12" v-if="errorResponse !== '' && errorResponse !== undefined">
        <Message severity="error" :sticky="true">{{errorResponse}}</Message>
      </div>
      <div class="p-col-12">
        <Button
          class="p-button-rounded p-button-raised"
          type="button"
          label="Continue"
          @click="onContinue"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Confirmation",
  props: {
    status: String,
    fallback: String,
    title: String,
    mutation: String,
    errorResponse: String
  },
  computed: {
    ...mapState("clubModule", ["status", "approvedClubs", "isShowBackButton"])
  },

  methods: {
    onContinue() {
      this.$router.push(this.fallback);
      this.$store.commit("clubModule/resetStatus");
    },
    onBackToCreate() {
      this.$store.commit(this.mutation);
      this.$store.commit("clubModule/resetStatus");
    }
  }
};
</script>

<style lang="scss" scoped>
:deep .pi {
  color: #2c3e50;
}
</style>