<template>
  <!-- For desktop -->
     <navbar-desktop-vue/>
     
    <!-- For Mobile -->
    <div class="centered-container p-input-filled">
      <div class="p-grid p-fluid p-m-0 max-w app">
        <div class="p-col-12 p-p-0">
          <router-view />
          <div
            v-if="
              $route.name !== 'welcome' &&
                $route.name !== 'register' &&
                $route.name !== 'home' &&
                $route.name !== 'login' &&
                $route.name !== 'emailconfirmation' &&
                $route.name !== 'forgot-password' &&
                $route.name !== 'faq'
            "
            class="footer"
          >
            <div class="p-d-flex p-jc-end">
              <div v-for="item in items" :key="item.label" class="p-field footer-icons p-col-4">
                <router-link link :to="item.to">
                  <i :class="item.icon"></i>
                  <span>{{ item.label }}</span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  
    <!-- Desktop Footer -->
    <footer-desktop-vue/>
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  import FooterDesktopVue from './components/shared/FooterDesktop.vue';
  import NavbarDesktopVue from './components/shared/NavbarDesktop.vue';
  
  const CURRENT_ROUTE = window.location.pathname;
  
  export default {
    components: { NavbarDesktopVue,FooterDesktopVue},
    data() {
      return {
        CURRENT_ROUTE: CURRENT_ROUTE,
        items: [
          {
            label: "Dashboard",
            icon: "pi pi-th-large",
            to: "/dashboard"
          },
          {
            label: "Settings",
            icon: "pi pi-fw pi-cog",
            to: "/settings"
          },
          {
            label: "Faq",
            icon: "pi pi-question-circle",
            to: "/faq"
          }
        ]
      };
    },
    computed: {
      ...mapGetters("authenticate", {
        isAuthenticated: "isAuthenticated"
      }),
      showNav() {
        return this.isAuthenticated && CURRENT_ROUTE === "/dashboard";
      }
    },
    methods:{
      isMobile() {
        if (screen.width <= 760) {
          return true;
        } else {
          return false;
        }
      },
    }
  };
  </script>
  <style lang="scss">
  body {
    background: #f7f7f7;
    margin-bottom: 100px;
    clear: both;
  }
  
  .pi {
    color: #ffffff;
  }
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }
  
  .app {
    justify-content: center;
    position: relative;
    top: 0;
    bottom: 0;
  }
  // This causes the Desktop middle part to be max-with of 400px
  // .max-w {
  //   max-width: 400px;
  // }
  .p-field.footer-icons span {
    display: block;
    color: white;
  }
  a {
    text-decoration: none;
  }
  
  @media only screen and (min-width: 801px) {
    .footer {
      position: fixed;
      width: 420px;
      margin: -0.5rem;
      background-color: #2c3e50;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      z-index: 1;
      color: #ffffff;
      padding-top: 10px;
      .p-d-flex {
        justify-content: center;
      }
    }
  }
  
  @media only screen and (max-width: 800px) {
    .footer {
      position: fixed;
      left: 0;
      bottom: 0;
      width: 100%;
      align-content: center;
      background-color: #2c3e50;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      z-index: 1;
      color: #ffffff;
      padding-top: 10px;
      .p-d-flex {
        justify-content: center;
      }
    }
  }
  </style>
  