<template>
   <img class="image" src="https://qspqa.quickscorepro.co.za/img/qspheaderlogo.png" alt="">
</template>

<script>
export default {
  name: 'header-logo',
}
</script>
<style lang="scss" scoped>
.image{
  width:250px;
}
</style>