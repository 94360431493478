<template>
  <div class="p-grid background">
    <div class="p-col-12">
      <Toast position="top-center" group="br" class="toast" />
      <div class="p-grid heading">
        <div class="p-col-2">
          <router-link to="/dashboard">
            <i class="pi pi-arrow-left"></i>
          </router-link>
        </div>
        <div class="p-col-8 h-title">
          <HeaderLogo />
          <h4>Edit score</h4>
        </div>
        <div class="p-col-2"></div>
      </div>
    </div>
    <div class="p-col-12 h-sub-title p-pt-0 p-pb-4">
      {{ selectedMatch.name }}
    </div>
    <div class="p-col-12 p-pb-4 search">
      <span class="p-input-icon-left">
        <i class="pi pi-search" />
        <AutoComplete
          v-model="selectedParticipant"
          :suggestions="participantResults"
          placeholder="Search by participant no"
          @complete="onParticipantNumberChange($event)"
          @item-select="onSelect"
          field="participant_No"
          :disabled="isUpdate"
        >
          <template #item="slotProps">
            <div class="p-text-left">
              {{
                `${slotProps.item.participant_No} - ${slotProps.item.firstName}`
              }}
            </div>
          </template>
        </AutoComplete>
      </span>
    </div>
    <div class="p-col-12 p-p-4 content-area" v-if="showRanges == false"></div>
    <div class="p-col-12 p-p-4 content-area" v-if="showRanges == true">
      <div class="p-grid item h-70-custom p-m-2">
        <div class="p-col-3 p-as-center">
          <Avatar
            :image="selectedUserDetails.image"
            size="large"
            shape="circle"
          />
        </div>
        <div class="p-col-7 p-as-center p-text-left">
          <div class="vertical-container">
            <div class="box name">
              {{
                selectedUserDetails.firstName +
                  " " +
                  selectedUserDetails.surname
              }}
            </div>
            <div class="id p-pt-2">
              Participant No.: {{ selectedUserDetails.participant_No }}
            </div>
          </div>
        </div>
      </div>
      <div class="p-col-12" v-for="range in matchRanges" :key="range.matchID">
        <div class="p-grid item h-70-custom" @click="onOpenRange(range)">
          <div class="p-col-12 p-as-center p-text-left p-pl-4">
            <div class="vertical-container">
              <div class="box">{{ range.name }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import HeaderLogo from "../../../shared/HeaderLogo.vue";
export default {
  name: "AdminMatchEdit",
  components: { HeaderLogo },
  created() {
    this.$store.commit("captureScoreModule/updateShowRanges", false);
  },
  data() {
    return {
      query: "",
      selectedParticipant: { participant_No: "" },
      isSelected: false,
      isUpdate: false
    };
  },
  computed: {
    ...mapState("captureScoreModule", [
      "selectedUserId",
      "selectedMatch",
      "matchRanges",
      "participantResults",
      "showRanges",
      "selectedUserDetails"
    ]),
    ...mapState("authenticate", ["user", "is_admin"])
  },
  methods: {
    async getParticipant(query) {
      await this.$store
        .dispatch("captureScoreModule/participant", {
          participantNo: query,
          matchId: this.selectedMatch.matchID
        })
        .then(() => {
          if (
            this.participantResults == null ||
            this.participantResults == ""
          ) {
            this.$toast.add({
              severity: "error",
              summary: "No participant found with this number.",
              group: "br",
              life: 2500
            });
          }
        });
      this.query = query;
    },
    async onParticipantNumberChange(event) {
      this.isSelected = false;
      if (event.query.length >= 1 && event.query !== this.query) {
        setTimeout(() => {
          this.getParticipant(event.query);
        }, 500);
      }
    },
    onSelect(e) {
      this.$store.commit("captureScoreModule/updateSelectedUserId", e.value.id);
      this.$store.commit(
        "captureScoreModule/updateSelectedUserDetails",
        e.value
      );
      this.$store.commit("captureScoreModule/updateShowRanges", true);
      this.$store.dispatch("captureScoreModule/getRangesForParticipant", {
        MatchID: this.selectedMatch.matchID,
        UserId: e.value.id
      });
    },
    onOpenRange(range) {
      this.$store.commit("captureScoreModule/updateSelectedRange", range);
      this.$store.dispatch("captureScoreModule/rangeScoresPerUser", {
        MatchId: this.selectedMatch.matchID,
        RangeID: range.rangeID,
        UserId: this.selectedUserId
      });
      this.$router.push({ name: "admin-participant-score-edit" });
    }
  }
};
</script>

<style lang="scss" scoped>
:deep .search {
  .p-input-filled .p-inputtext {
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
  }
  .p-inputtext {
    border-radius: 30px;
  }
}
</style>