<template>
  <div class="p-grid background">
    <div class="p-col-12">
      <div class="p-grid heading">
        <div class="p-col-2 arrow-padding">
          <router-link to="/dashboard">
            <i class="pi pi-arrow-left"></i>
          </router-link>
        </div>
        <div class="p-col-8 heading-text">
          <HeaderLogo />
          Settings
        </div>
        <div class="p-col-2" />
        <div class="p-col-2" />
      </div>
    </div>

    <div class="p-col-12 content-area">
      <div>
        <div>
          <div class="item h-70-custom pointer">
            <div class="p-col-2 p-as-center">
              <i><img src="../../assets/icons/user-icon.png"/></i>
            </div>

            <div class="p-col-8 p-as-center p-text-left">
              <div class="vertical-container">
                <div class="box profole-text-style">Profile</div>
              </div>
            </div>
            <div class="p-col-2 p-as-center p-text-left" @click="onOpenProfile">
              <div class="p-col-2 p-as-center">
                <i><img class="" src="../../assets/icons/expand.png"/></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderLogo from "../shared/HeaderLogo.vue";
export default {
  name: "Settings",
  components: { HeaderLogo },
  methods: {
    onOpenProfile() {
      this.$router.push({ name: "update-profile" });
    }
  }
};
</script>

<style lang="scss" scoped>
.heading-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  align-items: center;
  text-align: center;
  color: #ffffff;
}
.profole-text-style {
  height: 16px;
  left: 18.89%;
  right: 69.72%;
  top: calc(50% - 16px / 2 - 162px);
  font-family: Roboto;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #3e4547;
}

.arrow-padding {
  padding-top: 15px;
}

.centered-container .h-70-custom {
  height: 70px;
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
}
</style>