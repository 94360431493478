<template>
  <div class="p-grid centered-container h-70 container">
    <div class="p-col-12 p-pt-6">
      <div class="p-grid heading">
        <div class="p-col-1 p-pb-6">
          <router-link to="/home">
            <i class="pi pi-arrow-left"></i>
          </router-link>
        </div>
        <div class="p-col-11">
          <HeaderLogo />
        </div>
      </div>
    </div>
    <div class="p-col-12">
      <div class="box box-margin">
        <div class="p-fluid p-grid p-formgrid p-text-centre">
          <div class="p-col-12" v-if="emailConfirmation === 'loading'">
            <ProgressBar mode="indeterminate" class="progress" />
          </div>
        </div>
        <div
          class="confirmation-message"
          v-if="emailConfirmation === 'success'"
        >
          <label class="p-text-bold"
            >Your email address has been confirmed</label
          >
          <img
            class="tick-image"
            src="../../images/emailconfirmation.png"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import HeaderLogo from "../shared/HeaderLogo.vue";
export default {
  components: { HeaderLogo },
  name: "emailconfirmation",
  created() {
    this.$store.commit("authenticate/resetStatus");
    this.$store.dispatch("authenticate/emailConfirmation", {
      UserID: this.$route.params.userId
    });
  },
  computed: {
    ...mapState("authenticate", ["emailConfirmation"])
  }
};
</script>
<style lang="scss" scoped>
.heading {
  font-size: 20px;
  font-weight: bold;
  align-items: center;
  text-align: center;
  letter-spacing: 1.25px;
  color: #3e4547;

  .pi {
    color: #000000;
  }
}

.h-70 {
  height: 70vh;
}

.progress {
  height: 0.09em;
  width: 98%;
  margin-left: 2px;
}

.box-margin {
  margin-bottom: 450px;
}

.tick-image {
  width: 100px;
}

.confirmation-message {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: center;
  margin-top: 30%;
}

.container {
  overflow: hidden;
}
</style>