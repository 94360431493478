<template>
<div class="container" >
  <GMapMap
    :center="center"
    :zoom="5.5"
    map-type-id="terrain"
    style="position: relative; height:490px; border-radius:10px"
  >
    <GMapCluster :zoomOnClick="true">
      <GMapMarker
        :key="index"
        v-for="(m, index) in markers"
        :position="m.position"
        :clickable="true"
        :draggable="true"
        @click="center = m.position"
      />
    </GMapCluster>
  </GMapMap>
</div>
</template>

<script>
export default {
  name: 'maps-component',
  data() {
    return {
      center: { lat: -28.8166236, lng: 24.991639 },
      markers: [
        {
          position: {
            lat: 51.093048,
            lng: 6.84212,
          },
        },
        {
          position: {
            lat: 51.198429,
            lng: 6.69529,
          },
        },
        {
          position: {
            lat: 51.165218,
            lng: 7.067116,
          },
        },
        {
          position: {
            lat: 51.09256,
            lng: 6.84074,
          },
        },
      ],
    };
  },
};
</script>

<style>

.container{
  width: 90%;
}
</style>
