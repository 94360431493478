<template>
  <!-- For Desktop view -->
  <div v-if="!isMobile()">
    <h1 class="welcome-heading-desktop">HOWZIT</h1>
    <p class="welcome-text-desktop">
      Welcome to Quick Score Pro
    </p>
    <div class="p-col-12 p-pt-4">
      <img height="130" :src="logo" />
    </div>
    <!-- Here  -->
    <div class="p-col-12 p-pt-4 centered-container-welcome">
      <Splitter class="p-pt-4 info-section">
        <SplitterPanel>
          <Splitter layout="vertical" class="splitter-color">
            <SplitterPanel :size="85">
              <Splitter class="splitter-color">
                <SplitterPanel>
                  <div class="pointer info-section-icons-text">
                   <img
                      alt="logo"
                      src="../../assets/icons/fc-icon.png"
                      class="info-section-icons"
                    />
                    <!-- <label class="info-section-icons-text">FIND CLUBS </label> -->
                    <span class="info-section-icons-text">FIND CLUBS </span>
                  </div>

                  <div class="pointer info-section-icons-text">
                    <img
                      alt="logo"
                      src="../../assets/icons/fm-icon.png"
                      class="info-section-icons"
                    />
                    <label class="info-section-icons-text">FIND MATCHES </label>
                  </div>

                  <div class="pointer info-section-icons-text ">
                    <img
                      alt="logo"
                      src="../../assets/icons/rm-icon.png"
                      class="info-section-icons"
                    />
                    <label class="info-section-icons-text"
                      >REGISTERED MATCHES
                    </label>
                  </div>

                  <div class="pointer info-section-icons-text ">
                    <img
                      alt="logo"
                      src="../../assets/icons/ms-icon.png"
                      class="info-section-icons"
                    />
                    <label class="info-section-icons-text">MY SCORES </label>
                  </div>
                  
                </SplitterPanel>
                <SplitterPanel
                  class="flex align-items-center justify-content-center"
                >
                  <p class="info-section-text">
                    Quick Score Pro (QSP) is a platform to easily track and
                    manage shooting matches, manage clubs, branches and
                    registrations and to track personal scores. You can create a
                    club and build you own matches from your private target bank
                    with photos of the targets for participants and match
                    officials to see.
                  </p>

                  <div class="p-grid app">
                    <div class="col">
                      <Button
                        label="READ MORE"
                        style="background: none;"
                        class="info-section-read-more"
                        link
                      />
                    </div>
                  </div>
                </SplitterPanel>
              </Splitter>
            </SplitterPanel>

            <SplitterPanel
              class="flex align-items-center justify-content-center"
              
            >
                  <div class="p-grid app">
                    <div class="p-col-4" style="margin-top:-25px">
                      <Button label="GET STARTED" class="button-desktop" />
                    </div>
                  </div>
            </SplitterPanel>
          </Splitter>
        </SplitterPanel>
      </Splitter>
    </div>
  </div>

  <!-- For mobile view  -->
  <div v-if="isMobile()" class="p-grid centered-container">
    <div class="heading container">
      <div class="p-col-12 p-pt-4">
        <img :src="logo" />
      </div>

      <div class="p-col-12">
        <p class="welcome-text">
          Quick Score Pro (QSP) is a platform to easily track and manage
          shooting matches, manage clubs, branches and registrations and to
          track personal scores. You can create a club and build you own matches
          from your private target bank with photos of the targets for
          participants and match officials to see.
        </p>
        <p class="welcome-text">
          <span v-if="!readMoreActivated">{{ targets.slice(0, 205) }}</span>
          <span v-if="readMoreActivated" v-html="targets"></span>
        </p>
        <a
          class="read-more"
          v-if="!readMoreActivated"
          @click="activateReadMore"
          href="#"
          >Read more...</a
        >
        <p class="welcome-text">
          <span v-if="!readMoreActivated">{{ matches.slice(0, 0) }}</span>
          <span v-if="readMoreActivated" v-html="matches"></span>
        </p>
        <p class="welcome-text">
          <span v-if="!readMoreActivated">{{ login.slice(0, 0) }}</span>
          <span v-if="readMoreActivated" v-html="login"></span>
        </p>
      </div>
      <div class="p-col-12 p-pt-4 p-px-4">
        <Button
          label="GET STARTED"
          class="p-button-rounded p-button-raised p-px-4 p-m-0"
          @click="goHome"
        />
      </div>
      <div class="p-col-12">
        <Button label="FAQ" class="p-button-text p-px-2 p-m-0" @click="onFaq" />
      </div>
    </div>
  </div>
</template>

<script>
import logo from "../../assets/logo.png";
// import FaqDesktopVue from "./faq/FaqDesktop.vue";

export default {
  // components: { FaqDesktopVue },
  created() {
    if (location.href.includes("redirect")) {
      sessionStorage.setItem(
        "redirect-url",
        location.href.split("redirect=")[1]
      );
    }
  },
  data() {
    return {
      logo: logo,
      targets: `Targets in your target bank is used for creating your course or fire or ranges for your match with individual range descriptions.
          You can set the number of shots per range and the maximum points for the range.
          There are no limits to the amount of ranges that can be added to a match.
          It is all customizable to suite your needs.`,
      matches: `Matches can be created as a point scoring match or a time based match.
          You can also create your own categories and divisions for your matches and set fees per category.
          A club admin can add more admins to manage the club and admins can also add match officials to a specific match to capture scores for a specific match.`,
      login: `Log in as a user and find matches near you or search by club or by discipline.
          Quickly register for a match and track your scores live on the day of the shoot.
          Instantly check out leader boards and positions.
          Keep track of all your club shoots or your own shoots in one place.`,
      readMoreActivated: false,
    };
  },
  methods: {
    goHome() {
      this.$router.push({ name: "home" });
    },
    onFaq() {
      this.$router.push({ name: "faq" });
    },
    activateReadMore() {
      this.readMoreActivated = true;
    },
    isMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.welcome-text {
  line-height: 1.5;
}
.read-more {
  color: #8b0e0f;
}

// For desktop
.welcome-text-desktop {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 14.5px;
  line-height: 37px;
  align-items: center;
  text-align: center;
  letter-spacing: 3.03348px;
  color: #ffffff;
  text-shadow: 3.73352px 3.73352px 3.73352px rgba(0, 0, 0, 0.35);
  margin-bottom: -15px;
}
.welcome-heading-desktop {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 45px;
  margin-top: 3rem;
  line-height: 17px;
  letter-spacing: 3.9079px;
  color: #ffffff;
  text-shadow: 4.80973px 4.80973px 4.80973px rgba(0, 0, 0, 0.35);
}

.info-section {
  background-color: #093248;
  max-height: 710px;
  overflow: auto;
  // margin-left: -80%;
  // width: 65%;
  width: 950px;
  height: auto;

  // margin-left: 20px;
  background: #093248;
  border-radius: 50px;
  padding: 30px;
}

.info-section-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 30px;
  align-items: center;
  text-align: center;
  letter-spacing: 3.25px;
  color: #ffffff;
  text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.35);
}
.info-section-read-more {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 15px;
  line-height: 0px;
  align-items: center;
  text-align: center;
  letter-spacing: 3.25px;
  text-decoration-line: underline;
  color: #ffffff;
  text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.35);
  // margin-top: 20px;
  margin-bottom: 50px;
  background: none;
}

.info-section-icons {
  border-radius: 0.3px;
  height: 45px;
  margin-bottom: -1.5rem;
}

.info-section-icons-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  margin-left: 10px;
  align-items: left;
  text-align: left;
  letter-spacing: 3.25px;
  color: #ffffff;
  text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.35);
  line-height: 3.5rem;
}

.splitter-color {
  background-color: #093248;
}

.p-splitter {
  border-color: #093248;
}
</style>
