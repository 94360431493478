import { Service } from "./service";
import router from "../router";

class AuthService extends Service {
  constructor() {
    super();
  }

  async login(payload, cb, errorCb) {
    await this.https
      .post("Authentication/Login", payload)
      .then((res) => {
        cb(res.data);
        this.https.defaults.headers["Authorization"] =
          "Bearer " + res.data.token;
        if(sessionStorage.getItem("redirect-url")){
          router.push({ path: sessionStorage.getItem("redirect-url") });
          sessionStorage.removeItem("redirect-url");
        }else{
          router.push({ name: "dashboard" });
        }
      })
      .catch((error) => {
        errorCb(error);
      });
  }

  async forgotPassword(email, cb, errorCb) {
    await this.https
      .post(`authentication/GenerateResetPasswordLink/${email}`)
      .then((res) => {
        cb(res.data);
      })
      .catch((error) => {
        errorCb(error);
      });
  }

  async resetPassword(payload, cb, errorCb) {
    await this.https
      .post(`authentication/ResetPassword`, payload)
      .then((res) => {
        cb(res.data);
      })
      .catch((error) => {
        errorCb(error);
      });
  }

  async userSearch(payload, cb, errorCb) {
    await this.https
      .get(`Authentication/Search/${payload}`)
      .then((res) => {
        cb(res.data);
      })
      .catch((error) => {
        errorCb(error);
      });
  }

  async userSearchByName(payload, cb, errorCb) {
    await this.https
      .get(`Authentication/SearchByName/${payload}`)
      .then((res) => {
        cb(res.data);
      })
      .catch((error) => {
        errorCb(error);
      });
  }

  logout() {
    this.https.defaults.headers["Authorization"] = "";
    router.push({ name: "home" });
  }

  async registration(payload, cb, errorCb) {
    const headers = {
      "Content-Type": "multipart/form-data;",
    }
    const formData = new FormData();
    Object.keys(payload).forEach((key) => formData.append(key, payload[key]));
    await this.https
      .post("Authentication/Register", formData, { headers: headers })
      .then((res) => {
        cb(res.data);

        this.https.defaults.headers["Authorization"] =
          "Bearer " + res.data.token;
      })
      .catch((error) => {
        errorCb(error);
      });
  }

  async updateUserProfile(payload, cb, errorCb) {
    await this.https
      .put("Authentication/UpdateUserProfile", payload, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        cb(res.data);
      })
      .catch((error) => {
        errorCb(error);
      });
  }

  async updatePassword(payload, cb, errorCb) {
    await this.https
      .put(`authentication/UpdatePassword`, payload)
      .then((res) => {
        cb(res.data);
      })
      .catch((error) => {
        errorCb(error);
      });
  }

  async getUserById(payload, cb, errorCb) {
    await this.https
      .get(`Authentication/User/${payload}`)
      .then((res) => {
        cb(res.data);
      })
      .catch((error) => {
        errorCb(error);
      });
  }

  async emailConfirmation(payload, cb, errorCb) {
    await this.https
      .put("Authentication/ConfirmEmail", payload)
      .then((res) => {
        cb(res.data);
      })
      .catch((error) => {
        errorCb(error);
      });
  }

  async isOfficial(userId, cb, errorCb) {
    await this.https
      .get(`Match/checkIfOfficial/${userId}`)
      .then((res) => {
        cb(res.data);
      })
      .catch((error) => {
        errorCb(error);
      });
  }
}

export default new AuthService();
