<template>
      <div class="login-card card flex justify-content-center">
        <div class="p-fluid ">
          <div class="p-col-12">
            <ProgressBar
              v-if="status === 'loading'"
              mode="indeterminate"
              class="progress"
            />
          </div>
          <div class="p-col-12">
            <Message
              severity="error"
              v-if="status === 'error'"
              :life="3000"
              :sticky="false"
              >{{requestResponse.response !== undefined ? `${requestResponse.response}`: "Login failed. Please try again" }}</Message
            >
          </div>
          <div class="p-fluid">
            <p class="login-heading">LOGIN</p>
            <div class="p-field p-col-12">
              <span class="p-float-label p-input-icon-left">
                <i class="pi pi-user" />
                <InputText
                  id="email"
                  type="email"
                  autocomplete="username"
                  placeholder="Username or email"
                  v-model="login.email"
                  class="line-input"
                  :class="{
                    'p-invalid':
                      v$.login.email.required.$invalid ||
                      v$.login.email.email.$invalid,
                  }"
                />
              </span>
              <p class="error-message" v-if="v$.login.email.required.$invalid ||v$.login.email.email.$invalid">* Enter a valid email address</p>
            </div>
            <div class="p-field p-col-12">
              <span class="p-float-label p-input-icon-left">
                  <i class="pi pi-lock icon-styling" />
                  <InputText
                    type="password"
                    v-model="login.password"
                    toggleMask
                    placeholder="Password"
                    autocomplete="password"
                    class="line-input"
                    :feedback="false"
                    :class="{
                    'p-invalid':
                    v$.login.email.required.$invalid ||
                    v$.login.password.minLength.$invalid,
                    }"/>
              </span>
              <p class="error-message" v-if="v$.login.email.required.$invalid || v$.login.password.minLength.$invalid">* Enter a valid password</p>
            </div> 
          </div>       
            
          <span
            class="login-heading"
            @click="onForgotPassword"
          >
            FORGOT PASSWORD?
          </span>
          <br/>
          <Button
            type="button"
            class="p-button-rounded p-button-raised login-btn"
            :class= "[isDisabled ? disabled-login-button : login-button]"
            v-bind:disabled='!isDisabled'
            @click="auth"
            label="LOG IN"
          ></Button>
      </div>
      </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, email, minLength } from "@vuelidate/validators";
import { mapState } from "vuex";

export default {
  name: "login",
  components: { },
  setup() {
    return { v$: useVuelidate() };
  },
  created() {
    this.$store.commit("authenticate/resetStatus");
  },
  data() {
    return {
      login: {
        email: "",
        password: "",
      },
    };
  },
  validations: {
    login: {
      email: {
        required,
        email,
        $lazy: true,
      },
      password: {
        required,
        minLength: minLength(8),
        $lazy: true,
      },
    },
  },
  computed: {
    ...mapState("authenticate", ["status", "requestResponse"]),
    isDisabled() {
      var disabled = this.login.email.length > 0 && this.login.password.length > 0;
      return disabled;
    }
  },
  methods: {
    async auth() {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        await this.$store.dispatch("authenticate/login", this.login);
      }
    },
    onForgotPassword() {
      this.$router.push({ name: "forgot-password" });
    },
  },
};
</script>

<style lang="scss" scoped>
.heading {
  font-size: 20px;
  font-weight: bold;
  align-items: left;
  text-align: center;
  letter-spacing: 1.25px;
  color: #3E4547;

  .pi {
    color: #000000;
  }
}

.h-70 {
  height: 70vh;
}

.progress {
  height: 0.09em;
  width: 98%;
  margin-left: 2px;
}

.lock-icon {
  z-index: 1;
}

.box-margin {
  margin-bottom: 250px;
}

.disabled-login-button {
  background: #D8D8D8;
  height: 46.99px;
  color: #BDBDBD;
}

.login-button {
  height: 46.99px;
}

::v-deep .p-inputtext {
    padding-left: 45px;
}

.button-top-margin {
  margin-bottom: 50px;
}

.icon-styling{
  z-index: 1000;
}

.logo-padding {
  padding-top: 40px;
}

.login-btn {
  margin-top: 3em;
  margin-bottom: 2em;
  max-width: 50%;
  max-height: 45px;
}

.login-card { 
  background: hsl(201, 78%, 16%);
  border-radius: 50px;
  width: 40%;
  min-width: 500px;
  padding: 15px;
  // margin-top: 2em;
  margin-top: 5em;
  margin-bottom: 7em;
}

.login-heading {
  font-size: 20px;
  color: #FFFFFF;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  align-items: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.35);
}

html, body {
  height: 100%;
}

.line-input {
    background: transparent !important;
    border: none !important;
    border-bottom: 1px solid #FFFFFF !important;
    outline:none !important;
    box-shadow:none !important;
    border-radius: 0px !important;
}

.line-input-label {
  background: hsl(201, 78%, 16%);
}

::placeholder { 
  color: #BDBDBD;
}

</style>
