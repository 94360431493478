<template>
   <div @click="expand">
      <div class="p-grid item h-80-custom p-pl-1" v-if="!isExpanded">
         <div class="p-col-4 p-as-center pic">
            <Avatar class="p-mr-2" size="xlarge" :image="branch.image" />
         </div>
         <div
            class="p-col-8 p-as-center"
            :class="{ 'p-text-left': !isExpanded, 'p-text-right': isExpanded }"
            >
            <div class="vertical-container">
               <div class="p-col-12 title-mini p-pb-0">{{ branch.branchName }}</div>
               <div class="p-col-12 p-text-truncate p-pt-0">
                  <span class="sub-t-mini">Parent club: </span>
                  <span class="sub-value-mini">
                  {{ selectedClub.clubName }}
                  </span>
               </div>
            </div>
         </div>
      </div>
      <div class="p-grid item p-pl-1" v-if="isExpanded">
         <div class="p-col-4 p-as-center pic-x">
            <div class="vertical-container">
               <Avatar class="p-mr-2" size="xlarge" :image="branch.image" />
            </div>
         </div>
         <div
            class="p-col-8 p-as-center"
            :class="{ 'p-text-left': !isExpanded, 'p-text-right': isExpanded }"
            >
            <div class="vertical-container">
               <div class="p-grid">
                  <div class="p-col-12 p-pb-0 p-text-truncate name">
                     <span class="sub-value">{{ branch.branchName }}</span>
                  </div>
                  <div class="p-col-12 p-pb-0 p-text-truncate">
                     <span class="sub-t">Parent club: </span>
                     <span class="sub-value">{{ selectedClub.clubName }}</span>
                  </div>
                  <div class="p-col-12 p-pb-0 p-text-truncate">
                     <span class="sub-t">Email: </span>
                     <span class="sub-value">
                     {{ selectedClub.email }}
                     </span>
                  </div>
                  <div class="p-col-12 p-pb-4 p-text-truncate">
                     <span class="sub-t">Admin: </span
                        ><span class="sub-value">{{ branch.adminName }} {{ branch.adminSurname }}</span>
                  </div>
                  <input type="hidden" v-bind:id="selectedClub.clubName" :value="selectedClub.email">
                  <div
                     class="action"
                     :class="{ 'p-col-6': !is_admin, 'p-col-8': is_admin }"
                     >
                     CLOSE
                  </div>
                  <div class="p-col-4 action" @click="onEdit" v-if="is_admin">
                     EDIT
                  </div>
                  <div class="p-col-6 action" 
                     v-if="!is_admin"  
                     v-clipboard:copy="`${selectedClub.email}`"
                     v-clipboard:success="onCopy"
                     >
                     COPY
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Branch",

  props: {
    branch: {},
  },
  data() {
    return { isExpanded: false };
  },
  computed: {
    ...mapState("clubModule", ["selectedClub"]),
    ...mapState("authenticate", ["is_admin"]),
  },
  methods: {
    onEdit() {
      this.$store.commit("branchModule/updateSelectedBranch", this.branch);
      this.$router.push({ name: "update-branch" });
    },
    expand() {
      this.isExpanded = !this.isExpanded;
    },
    onCopy() {
      this.$toast.add({
        severity: "success",
        summary: "Copied",
        life: 1000,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.title-mini {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #0a0a0a;
}
.sub-t-mini {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: #979797;
}

.sub-value-mini {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #979797;
}

.name {
  font-weight: bold;
  font-size: 20px;
  text-align: left;
  letter-spacing: 0.15px;
  color: #000000da;
}

.sub-t {
  font-weight: bold;
  font-size: 14px;
  text-align: right;
  letter-spacing: 0.25px;
  color: #00000094;
}

.sub-value {
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  letter-spacing: 0.25px;
  color: #828282;
}

.pic {
  background: #e6e6e6;
  height: 75px;
}

.pic-x {
  background: #e6e6e6;
  height: 165px;
}
.p-grid {
  text-align: left;
}
</style>