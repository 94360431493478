<template>
  <div>
    <confirmation
      v-if="isSubmitted && !goBack"
      :title="'New category has successfully been created'"
      :status="createCategoryStatus"
      :fallback="'/club'"
      :mutation="'categoryModule/create_another_category'"
    />

    <div class="p-grid background" v-if="!isSubmitted || goBack">
      <div class="p-col-12">
        <div class="p-grid heading">
          <div class="p-col-2">
            <router-link to="/club">
              <i class="pi pi-arrow-left"></i>
            </router-link>
          </div>
          <div class="p-col-8">
            <HeaderLogo />
            Match category
          </div>
          <div class="p-col-2" />
        </div>
      </div>

      <div class="p-col-12 p-pt-4 content-area">
        <div class="p-fluid p-formgrid p-grid p-p-4">
          <div class="p-field p-col-12">
            <span class="p-input-icon-left">
              <i class="pi pi-globe" />
              <InputText
                type="text"
                placeholder="Category name"
                v-model="categoryModel.name"
                :class="{
                  'p-invalid': v$.categoryModel.name.required.$invalid
                }"
              />
            </span>
            <p
              class="error-message"
              v-if="v$.categoryModel.name.required.$invalid"
            >
              * Category name is required
            </p>
          </div>

          <div class="p-field p-col-12 p-pt-4">
            <Button
              class="p-button-rounded p-button-raised"
              type="button"
              @click="createCategory()"
              label="Create "
              ><ProgressSpinner
                v-if="createCategoryStatus === 'loading'"
                class="progress-spinner-custom"
                strokeWidth="5"
                animationDuration=".5s"
              />
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { mapState } from "vuex";
import Confirmation from "../Confirmation.vue";
import HeaderLogo from "../../shared/HeaderLogo.vue";

export default {
  components: { Confirmation, HeaderLogo },
  name: "createCategory",
  setup() {
    return { v$: useVuelidate() };
  },
  created() {
    this.$store.commit("categoryModule/resetStatus");
  },
  data() {
    return {
      isSubmitted: false,
      categoryModel: {
        name: "",
        clubID: ""
      }
    };
  },
  validations: {
    categoryModel: {
      name: {
        required,
        $lazy: true
      }
    }
  },
  computed: {
    ...mapState("categoryModule", ["createCategoryStatus", "goBack"]),
    ...mapState("clubModule", ["selectedClub"]),
    ...mapState("authenticate", ["user"])
  },

  methods: {
    async createCategory() {
      this.$store.commit("categoryModule/resetStatus");
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.isSubmitted = true;
        this.categoryModel.clubID = this.selectedClub.clubID;
        await this.$store
          .dispatch("categoryModule/create", this.categoryModel)
          .then(() => {
            (this.categoryModel.name = ""),
              this.$store.commit("clubModule/show_back_button");
          });
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
