import UtilService from "../../services/util.service";

const state = () => ({
  status: "",
  provinces: [],
  getFaqsStatus: "",
  faqs: [],
});

// getters
const getters = {
  //
};

// actions
const actions = {
  provinces({ commit }) {
    UtilService.getProvinces(
      (res) => {
        commit("get_provinces_success", res.response);
      },
      (error) => {
        commit("get_provinces_error");
        console.error("error", error);
      }
    );
  },
  faqs({ commit }) {
    commit("get_faqs_request");
    UtilService.getFaqs(
      (res) => {
        commit("get_faqs_success", res.response);
      },
      (error) => {
        commit("get_faqs_error");
        console.error("error", error);
      }
    );
  },
};

// mutations
const mutations = {
  get_provinces_success(state, provinces) {
    state.provinces = provinces;
    state.status = "success";
  },
  get_provinces_error(state) {
    state.status = "error";
  },
  //
  get_faqs_request(state) {
    state.getFaqsStatus = "loading";
  },
  get_faqs_success(state, faqs) {
    state.faqs = faqs;
    state.getFaqsStatus = "success";
  },
  get_faqs_error(state) {
    state.getFaqsStatus = "error";
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
