<template>
  <div class="p-grid item h-60-custom club-item pointer">
    <div class="p-col-4 p-as-center"> 
      <Avatar class="p-mr-2" size="xlarge" :image="club.image" />
    </div>

    <div class="p-col-8 p-as-center p-text-left h-text">
      <div class="vertical-container">
        <div class="box">{{ club.clubName }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Club",
  props: {
    club: {},
  },
};
</script>

<style lang="scss" scoped>
.club-item {
  .p-avatar.p-avatar-xl {
    width: 100px;
    height: 40px;
    font-size: 2rem;
    object-fit: contain;
  img {
    object-fit: cover;
  }
  }
}

</style>